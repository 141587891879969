import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Table, Tag } from "antd"
// eslint-disable-next-line import/no-cycle
import AuthorTable from "./AuthorTable"
import { articleType, authorType } from "./types"
import { useArticleListContext } from "./Context"
import {
  ARTICLE_STATUS_COLOR,
  ARTICLE_STATUS_ICON_RENDER,
  ARTICLE_STATUS_ORDER,
  ARTICLE_STATUS_TEXT,
} from "./constants"

const columns = [
  {
    title: "DOI",
    dataIndex: "doi",
    key: "doi",
    render: (doi) => (
      <a
        href={`//doi.org/${doi}`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {doi}
      </a>
    ),
  },
  { title: "Title", dataIndex: "title", key: "title" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag
        color={ARTICLE_STATUS_COLOR[status]}
        icon={ARTICLE_STATUS_ICON_RENDER[status]()}
      >
        {ARTICLE_STATUS_TEXT[status]}
      </Tag>
    ),
    sorter: {
      compare: (a, b) =>
        ARTICLE_STATUS_ORDER[a.status] - ARTICLE_STATUS_ORDER[b.status],
      multiple: 5,
    },
  },
  {
    title: "Publication Date",
    dataIndex: "",
    key: "publicationDate",
    render: (_, { publicationYear, publicationMonth, publicationDay }) => {
      return publicationYear ? (
        <Tag color="magenta">
          {`${
            publicationMonth
              ? `${
                  publicationDay ? `${publicationDay}-` : ""
                }${publicationMonth}-`
              : ""
          }${publicationYear}`}
        </Tag>
      ) : null
    },
    sorter: {
      compare: (a, b) => {
        if ((a.publicationYear || 0) < (b.publicationYear || 0)) {
          return -1
        }
        if ((a.publicationYear || 0) > (b.publicationYear || 0)) {
          return 1
        }
        if ((a.publicationMonth || 0) < (b.publicationMonth || 0)) {
          return -1
        }
        if ((a.publicationMonth || 0) > (b.publicationMonth || 0)) {
          return 1
        }
        if ((a.publicationDay || 0) < (b.publicationDay || 0)) {
          return -1
        }
        if ((a.publicationDay || 0) > (b.publicationDay || 0)) {
          return 1
        }
        return 0
      },
      multiple: 4,
    },
  },
]

const ArticleTable = ({ articleDois }) => {
  const { articlesByDoi, authorsById } = useArticleListContext()
  const articles = useMemo(
    () => articleDois.map((doi) => articlesByDoi[doi] || { doi }),
    [articlesByDoi, articleDois]
  )
  const expandedRowRender = (record, index, indent, expanded) => {
    const { authorIds } = record
    return (
      <AuthorTable
        /* articlesByDoi={articlesByDoi}
        authorsById={authorsById} */
        authorIds={authorIds}
      />
    )
  }
  return (
    <Table
      columns={columns}
      expandable={{ expandedRowRender }}
      dataSource={articles}
      rowKey="doi"
      bordered
      pagination={{
        showSizeChanger: true,
      }}
      scroll={{ x: "calc(800px + 25%)" }}
    />
  )
}

ArticleTable.propTypes = {
  /* articlesByDoi: PropTypes.objectOf(articleType),
  authorsById: PropTypes.objectOf(authorType), */
  articleDois: PropTypes.arrayOf(PropTypes.string),
}

ArticleTable.defaultProps = {
  /* articlesByDoi: {},
  authorsById: {}, */
  articleDois: [],
}

export default ArticleTable
