/* colors obtained from https://colorbrewer2.org/ */

const colorSchemesRaw = {
  all: [
    [
      { r: 141, g: 211, b: 199 },
      { r: 255, g: 255, b: 179 },
      { r: 190, g: 186, b: 218 },
      { r: 251, g: 128, b: 114 },
      { r: 128, g: 177, b: 211 },
      { r: 253, g: 180, b: 98 },
      { r: 179, g: 222, b: 105 },
      { r: 252, g: 205, b: 229 },
      { r: 217, g: 217, b: 217 },
      { r: 188, g: 128, b: 189 },
      { r: 204, g: 235, b: 197 },
      { r: 255, g: 237, b: 111 },
    ],
    [
      { r: 166, g: 206, b: 227 },
      { r: 31, g: 120, b: 180 },
      { r: 178, g: 223, b: 138 },
      { r: 51, g: 160, b: 44 },
      { r: 251, g: 154, b: 153 },
      { r: 227, g: 26, b: 28 },
      { r: 253, g: 191, b: 111 },
      { r: 255, g: 127, b: 0 },
      { r: 202, g: 178, b: 214 },
      { r: 106, g: 61, b: 154 },
      { r: 255, g: 255, b: 153 },
      { r: 177, g: 89, b: 40 },
    ],
    [
      { r: 158, g: 1, b: 66 },
      { r: 213, g: 62, b: 79 },
      { r: 244, g: 109, b: 67 },
      { r: 253, g: 174, b: 97 },
      { r: 254, g: 224, b: 139 },
      { r: 255, g: 255, b: 191 },
      { r: 230, g: 245, b: 152 },
      { r: 171, g: 221, b: 164 },
      { r: 102, g: 194, b: 165 },
      { r: 50, g: 136, b: 189 },
      { r: 94, g: 79, b: 162 },
    ],
    [
      { r: 165, g: 0, b: 38 },
      { r: 215, g: 48, b: 39 },
      { r: 244, g: 109, b: 67 },
      { r: 253, g: 174, b: 97 },
      { r: 254, g: 224, b: 139 },
      { r: 255, g: 255, b: 191 },
      { r: 217, g: 239, b: 139 },
      { r: 166, g: 217, b: 106 },
      { r: 102, g: 189, b: 99 },
      { r: 26, g: 152, b: 80 },
      { r: 0, g: 104, b: 55 },
    ],
  ],
  colorblind: [
    [
      { r: 165, g: 0, b: 38 },
      { r: 215, g: 48, b: 39 },
      { r: 244, g: 109, b: 67 },
      { r: 253, g: 174, b: 97 },
      { r: 254, g: 224, b: 144 },
      { r: 255, g: 255, b: 191 },
      { r: 224, g: 243, b: 248 },
      { r: 171, g: 217, b: 233 },
      { r: 116, g: 173, b: 209 },
      { r: 69, g: 117, b: 180 },
      { r: 49, g: 54, b: 149 },
    ],
    [
      { r: 103, g: 0, b: 31 },
      { r: 178, g: 24, b: 43 },
      { r: 214, g: 96, b: 77 },
      { r: 244, g: 165, b: 130 },
      { r: 253, g: 219, b: 199 },
      { r: 247, g: 247, b: 247 },
      { r: 209, g: 229, b: 240 },
      { r: 146, g: 197, b: 222 },
      { r: 67, g: 147, b: 195 },
      { r: 33, g: 102, b: 172 },
      { r: 5, g: 48, b: 97 },
    ],
    [
      { r: 127, g: 59, b: 8 },
      { r: 179, g: 88, b: 6 },
      { r: 224, g: 130, b: 20 },
      { r: 253, g: 184, b: 99 },
      { r: 254, g: 224, b: 182 },
      { r: 247, g: 247, b: 247 },
      { r: 216, g: 218, b: 235 },
      { r: 178, g: 171, b: 210 },
      { r: 128, g: 115, b: 172 },
      { r: 84, g: 39, b: 136 },
      { r: 45, g: 0, b: 75 },
    ],
    [
      { r: 64, g: 0, b: 75 },
      { r: 118, g: 42, b: 131 },
      { r: 153, g: 112, b: 171 },
      { r: 194, g: 165, b: 207 },
      { r: 231, g: 212, b: 232 },
      { r: 247, g: 247, b: 247 },
      { r: 217, g: 240, b: 211 },
      { r: 166, g: 219, b: 160 },
      { r: 90, g: 174, b: 97 },
      { r: 27, g: 120, b: 55 },
      { r: 0, g: 68, b: 27 },
    ],
  ],
}

const colorSchemes = {}

function rgbToHsl(rgbColor) {
  let { r, g, b } = rgbColor
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h
  let s
  const l = (max + min) / 2

  if (max === min) {
    // eslint-disable-next-line no-multi-assign
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    // eslint-disable-next-line default-case
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  h = (h * 360) % 360

  return { h, s, l }
}

let ready = false

const init = () => {
  if (ready) return
  Object.entries(colorSchemesRaw).forEach(
    ([colorSchemeType, colorSchemeList]) => {
      colorSchemes[colorSchemeType] = colorSchemeList.map((colorScheme) =>
        colorScheme.map((rgbColor) => ({
          rgbColor,
          hslColor: rgbToHsl(rgbColor),
          rgbColorString: `rgb(${rgbColor.r},${rgbColor.g},${rgbColor.b})`,
          colorStrings: [],
        }))
      )
    }
  )
  colorSchemes.all.push(...colorSchemes.colorblind)
  ready = true
}

const ALPHA = 0.8

export const getColorString = ({ isColorblind }) => (colorSchemeIndex) => (
  colorIndex
) => {
  init()
  const colorScheme =
    colorSchemes[isColorblind ? "colorblind" : "all"][colorSchemeIndex]
  const color = colorScheme[colorIndex % colorScheme.length]
  const hueShift = Math.floor(colorIndex / colorScheme.length)
  if (!color.colorStrings[hueShift]) {
    let { h, s, l } = color.hslColor
    h = (h + hueShift * 30) % 360
    s = (s * 100).toFixed(2)
    l = (l * 100).toFixed(2)
    const a = ALPHA.toFixed(3)
    color.colorStrings[hueShift] = `hsla(${h}, ${s}%, ${l}%, ${a})`
  }
  return color.colorStrings[hueShift]
}

/**
 *
 * @param isColorblind
 * @returns {(({ rgbColorString: string })[])[]}
 */
export const getColorSchemes = ({ isColorblind }) =>
  isColorblind ? colorSchemes.colorblind : colorSchemes.all
