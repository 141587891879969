import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"

const ScrollView = ({ children, className }) => {
  return (
    <Layout.Content
      className={`overflow-auto w-full h-full pt-2 pb-4 sm:px-2 md:px-6 lg:px-12 xl:px-1 ${className}`}
    >
      {children}
    </Layout.Content>
  )
}

ScrollView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

ScrollView.defaultProps = {
  children: null,
  className: "",
}

export default ScrollView
