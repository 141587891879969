import React, { useEffect, useState } from "react"
import * as popoto from "popoto"
import { useSelector } from "react-redux"

import { selectNeo4j } from "../../../../store/reducers/neo4j"
import { Neo4JHelper } from "../../../../helpers"

const boltToRestUrl = (boltUrl) =>
  `${boltUrl
    .replace(
      "bolt://",
      process.env.NODE_ENV === "development" ? "http://" : "https://"
    )
    .replace(
      ":7687",
      process.env.NODE_ENV === "development" ? ":7474" : ":7473"
    )}/db/data/transaction/commit`

let started = false

const usePopoto = () => {
  const { url, username, password } = useSelector(selectNeo4j)

  const [count, setCount] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const [authInitialized, setAuthInitialized] = useState(false)

  useEffect(() => {
    popoto.provider.node.Provider = Neo4JHelper.Entities.keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          returnAttributes: [
            ...Neo4JHelper.Entities[key].strings,
            ...Neo4JHelper.Entities[key].numbers,
          ],
        },
      }),
      {}
    )
    popoto.result.RESULTS_PAGE_SIZE = 100
    popoto.result.onTotalResultCount((c) => setCount(c))
    popoto.logger.LEVEL = popoto.logger.LogLevels.INFO
    popoto.tools.TOGGLE_TAXONOMY = true
    /* popoto.tools.SAVE_GRAPH = true */
    popoto.query.USE_RELATION_DIRECTION = false
    popoto.graph.link.SHOW_MARKER = true

    setInitialized(true)
  }, [])
  useEffect(() => {
    if (url && username && password) {
      popoto.rest.CYPHER_URL = boltToRestUrl(url)
      popoto.rest.AUTHORIZATION = `Basic ${btoa(`${username}:${password}`)}`
      setAuthInitialized(true)
    }
  }, [url, username, password])
  useEffect(() => {
    if (initialized && authInitialized) {
      if (started) {
        popoto.update()
      } else {
        popoto.start("Author")
        started = true
      }
    }
  }, [initialized, authInitialized])
  return { count }
}

export default usePopoto
