import React from "react"
import PropTypes from "prop-types"
import { Spin, Alert, Button } from "antd"
import { ReloadOutlined } from "@ant-design/icons"

const InnerLoading = ({ loading, error, retry, size }) => {
  if (loading) {
    return (
      <Spin
        size={size === "xlarge" ? undefined : size}
        className={size === "xlarge" ? "ant-spin-xl" : undefined}
      />
    )
  }
  if (error) {
    return (
      <Alert
        showIcon
        closable={false}
        type="error"
        message={error}
        description={
          retry ? (
            <Button onClick={retry} icon={<ReloadOutlined />}>
              Retry
            </Button>
          ) : undefined
        }
      />
    )
  }
  return null
}

InnerLoading.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  retry: PropTypes.func,
  size: PropTypes.oneOf(["small", "middle", "large", "xlarge"]),
}

InnerLoading.defaultProps = {
  loading: true,
  error: undefined,
  retry: undefined,
  size: undefined,
}

const Loading = ({
  render,
  loading,
  loaded,
  error,
  retry,
  overlay,
  children,
  size,
  className,
  containerRef,
}) => {
  if (overlay) {
    return render(
      <div
        className={`w-full h-full text-center relative ${className}`}
        ref={containerRef}
      >
        {loaded ? children : null}
        {loading || error ? (
          <>
            <div
              className={`w-full h-full text-center opacity-75 bg-gray-300 absolute inset-0 ${className}`}
            />
            <div className="w-full h-full bg-transparent flex items-center justify-center absolute inset-0">
              <InnerLoading
                loading={loading}
                error={error}
                retry={retry}
                size={size}
              />
            </div>
          </>
        ) : null}
      </div>
    )
  }
  if (loading || error) {
    return render(
      <div
        className={`h-full text-center ${className} flex items-center justify-center`}
        ref={containerRef}
      >
        <InnerLoading
          loading={loading}
          error={error}
          retry={retry}
          size={size}
        />
      </div>
    )
  }
  if (loaded) {
    return render(children)
  }
  return render(null)
}

Loading.propTypes = {
  render: PropTypes.func,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  retry: PropTypes.func,
  overlay: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "middle", "large", "xlarge"]),
  className: PropTypes.string,
  containerRef: PropTypes.func,
}

Loading.defaultProps = {
  render: (loader) => loader,
  loading: true,
  loaded: true,
  error: undefined,
  retry: undefined,
  overlay: false,
  children: null,
  size: undefined,
  className: "",
  containerRef: undefined,
}

export default Loading
