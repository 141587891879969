export const STATUSES = {
  NEW: 0,
  COPYING_TO_CRAWLER_DB: 1,
  COPIED_PROCESSING: 2,
  SUCCESS: 3,
}

export const ERRORS = {
  UNKNOWN: -1,
}

export const CRAWLER_COUNT = 1

export default {
  STATUSES,
  ERRORS,
  CRAWLER_COUNT,
}
