import PropTypes from "prop-types"

export const articleType = PropTypes.shape({
  doi: PropTypes.string.isRequired,
  title: PropTypes.string,
  status: PropTypes.string,
})

export const authorType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  authorId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  affiliations: PropTypes.arrayOf(
    PropTypes.shape({
      affiliationId: PropTypes.string,
      name: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    })
  ),
  eigenvector: PropTypes.number,
  betweenness: PropTypes.number,
  closeness: PropTypes.number,
  degree: PropTypes.number,
  cluster: PropTypes.number,
})
