import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Table, Tag } from "antd"
import { SyncOutlined } from "@ant-design/icons"
// eslint-disable-next-line import/no-cycle
import ArticleTable from "./ArticleTable"
import { articleType, authorType } from "./types"
import { useArticleListContext } from "./Context"

const AuthorInfluence = ({ author }) => {
  const {
    helpers: { getInfluence },
  } = useArticleListContext()
  return getInfluence(author).toFixed(2)
}

AuthorInfluence.propTypes = {
  author: authorType.isRequired,
}

const ClusterColorTag = ({ cluster }) => {
  const {
    helpers: { getClusterColor },
  } = useArticleListContext()
  return (
    <Tag color={getClusterColor(cluster)} className="text-black">
      Cluster #{cluster + 1}
    </Tag>
  )
}

ClusterColorTag.propTypes = {
  cluster: PropTypes.number.isRequired,
}

const stringSort = (a, b) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: {
      compare: (a, b) => stringSort(a.firstName, b.firstName),
    },
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: {
      compare: (a, b) => stringSort(a.lastName, b.lastName),
    },
  },
  {
    title: "Affiliations",
    dataIndex: "affiliations",
    key: "affiliations",
    render: (affiliations) => (
      <div className="flex flex-col flex-no-wrap items-stretch justify-start">
        {(affiliations || []).map(({ affiliation, city, country }, index) => (
          <Tag
            key={
              /* eslint-disable-next-line react/no-array-index-key */
              `affil-${index}`
            }
            className="text-sm flex-wrap justify-between"
          >
            <Tag
              className={`mr-2 ml-0 leading-tight whitespace-normal ${
                /* prevent max-len :P */ ""
              } break-words subpixel-antialiased text-xs font-semibold`}
              color="#2db7f5"
            >
              {affiliation}
            </Tag>
            <span className="inline-flex flex-no-wrap">
              {`${city ? `${city}/` : ""}${country}`}
            </span>
          </Tag>
        ))}
      </div>
    ),
  },
  {
    title: "Cluster",
    dataIndex: "cluster",
    key: "cluster",
    render: (cluster) =>
      typeof cluster === "number" ? (
        <ClusterColorTag cluster={cluster} />
      ) : (
        <SyncOutlined spin />
      ),
    sorter: {
      compare: (a, b) => a.cluster - b.cluster,
      multiple: 10,
    },
  },
  {
    title: "Influence",
    dataIndex: "influence",
    key: "influence",
    render: (influence) => (influence || 0).toFixed(2),
    sorter: {
      compare: (a, b) => a.influence - b.influence,
      multiple: 9,
    },
  },
  {
    title: "Eigenvector Centrality",
    dataIndex: "eigenvector",
    key: "eigenvector",
    render: (centrality) => (centrality || 0).toFixed(2),
    sorter: {
      compare: (a, b) => a.eigenvector - b.eigenvector,
      multiple: 8,
    },
  },
  {
    title: "Closeness Centrality",
    dataIndex: "closeness",
    key: "closeness",
    render: (centrality) => (centrality || 0).toFixed(2),
    sorter: {
      compare: (a, b) => a.closeness - b.closeness,
      multiple: 8,
    },
  },
  {
    title: "Betweenness Centrality",
    dataIndex: "betweenness",
    key: "betweenness",
    render: (centrality, { betweennessRaw }) =>
      `${(betweennessRaw || 0).toFixed(2)} (${(centrality || 0).toFixed(2)})`,
    sorter: {
      compare: (a, b) => a.betweenness - b.betweenness,
      multiple: 8,
    },
  },
  {
    title: "Degree Centrality",
    dataIndex: "degree",
    key: "degree",
    render: (degree, { degreeRaw }) =>
      `${degreeRaw || 0} (${(degree || 0).toFixed(2)})`,
    sorter: {
      compare: (a, b) => a.degree - b.degree,
      multiple: 8,
    },
  },
]

const AuthorTable = ({ authorIds }) => {
  const { articlesByDoi, authorsById } = useArticleListContext()
  const authors = useMemo(
    () => authorIds.map((id) => authorsById[id] || { id }),
    [authorsById, authorIds]
  )
  const expandedRowRender = (record, index, indent, expanded) => {
    const { articleDois } = record
    return (
      <ArticleTable
        /* articlesByDoi={articlesByDoi}
        authorsById={authorsById} */
        articleDois={articleDois}
      />
    )
  }
  return (
    <Table
      columns={columns}
      expandable={{ expandedRowRender }}
      dataSource={authors}
      rowKey="id"
      bordered
      pagination={{
        showSizeChanger: true,
      }}
      scroll={{ x: "calc(900px + 25%)" }}
    />
  )
}

AuthorTable.propTypes = {
  /* articlesByDoi: PropTypes.objectOf(articleType),
  authorsById: PropTypes.objectOf(authorType), */
  authorIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
}

AuthorTable.defaultProps = {
  /* articlesByDoi: {},
  authorsById: {}, */
  authorIds: [],
}

export default AuthorTable
