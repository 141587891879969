import React, { useEffect, useState } from "react"
import Neovis from "neovis.js"
import { useSelector } from "react-redux"

import { selectNeo4j } from "../../../../store/reducers/neo4j"
import {
  selectNeovisLabels,
  selectNeovisRelationships,
} from "../../../../store/reducers/neovis"
import { initialCypherQuery } from "../constants"

const hideLabelCaption = (label) =>
  label.caption === "false" ? { ...label, caption: () => "" } : label
const hideConfigLabelsCaptions = (o) =>
  Object.keys(o).reduce(
    (acc, key) => ({
      ...acc,
      [key]: hideLabelCaption(o[key]),
    }),
    {}
  )

const useNeovis = (neovisNode, cypherQuery = initialCypherQuery) => {
  const { url, username, password } = useSelector(selectNeo4j)
  const labels = useSelector(selectNeovisLabels)
  const relationships = useSelector(selectNeovisRelationships)

  const [viz, setViz] = useState(null)
  useEffect(() => {
    if (neovisNode) {
      const config = {
        container_id: neovisNode.id,
        server_url: url,
        server_user: username,
        server_password: password,
        labels: hideConfigLabelsCaptions(labels),
        relationships,
        initial_cypher: cypherQuery || initialCypherQuery,
        encrypted:
          process.env.NODE_ENV === "development"
            ? "ENCRYPTION_OFF"
            : "ENCRYPTION_ON",
      }
      console.log("config", config)
      if (viz) {
        viz.reinit(config)
      } else {
        const nextViz = new Neovis(config)
        nextViz.render()
        setViz(nextViz)
      }
    } else {
      setViz(null)
    }
  }, [neovisNode, url, username, password, labels, relationships])

  useEffect(() => {
    if (viz) {
      if (!cypherQuery) {
        viz.clearNetwork()
      } else if (cypherQuery !== viz._query) {
        viz.clearNetwork()
        viz.renderWithCypher(cypherQuery)
      }
    }
  }, [cypherQuery, viz])

  return viz
}

export default useNeovis
