import React, { useState } from "react"

import { Card, Typography, Layout, Form, Input, Button } from "antd"

import { CenterView } from "../../../layouts"
import NeovisGraph from "./components/NeovisGraph"
import { initialCypherQuery } from "./constants"
import NeovisOptions from "./components/NeovisOptions"

const Neovis = () => {
  const [form] = Form.useForm()
  const [cypherQuery, setCypherQuery] = useState()

  return (
    <Layout className="h-full">
      <Layout>
        <Layout.Header className="h-auto py-2 bg-red-200 px-4">
          <Form
            form={form}
            layout="inline"
            onFinish={(d) => setCypherQuery(d.cypherQuery)}
            className="flex items-stretch content-center justify-center"
            initialValues={{
              cypherQuery: initialCypherQuery,
            }}
          >
            <Form.Item name="cypherQuery" noStyle>
              <Input.TextArea
                className="w-64 flex-auto"
                placeholder="Enter cypher query"
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" className="h-auto">
                Run
              </Button>
            </Form.Item>
          </Form>
        </Layout.Header>
        <Layout.Content>
          <NeovisGraph cypherQuery={cypherQuery} />
        </Layout.Content>
      </Layout>
      <Layout.Sider theme="light">
        <NeovisOptions />
      </Layout.Sider>
    </Layout>
  )
}

const styles = {
  card: {
    flex: 1,
    alignSelf: "stretch",
    display: "flex",
  },
  cardBody: {
    flex: 1,
  },
}

export default Neovis
