// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import createAuth0Client from "@auth0/auth0-spa-js"
import PropTypes from "prop-types"

const config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH_API_AUIDENCE,
  redirect_uri: window.location.origin,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
}

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(config)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()

        history.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        )
      }

      const isAuthenticatedNext = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticatedNext)

      if (isAuthenticatedNext) {
        const userNext = await auth0FromHook.getUser()
        setUser(userNext)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const userNext = await auth0Client.getUser()
    setUser(userNext)
    setIsAuthenticated(true)
    if ((location.state || {}).from) {
      history.replace({ pathname: location.state.from })
    }
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const userNext = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(userNext)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
Auth0Provider.propTypes = {
  children: PropTypes.node,
}
Auth0Provider.defaultProps = {
  children: null,
}
