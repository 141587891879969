import React from "react"
import { useLocation } from "react-router-dom"

import { Card, Typography, Button } from "antd"

import { CenterView } from "../../../layouts"
import { useAuth0 } from "../../../modules/auth0"

const Home = () => {
  const { isLoading, loginWithPopup } = useAuth0()
  const location = useLocation()
  return (
    <CenterView>
      <Card>
        <Typography.Title level={1}>Welcome to Sonar !</Typography.Title>
        <Button
          type="primary"
          size="large"
          loading={isLoading}
          onClick={loginWithPopup}
          className="w-full"
        >
          Please login!
        </Button>
      </Card>
    </CenterView>
  )
}

export default Home
