import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import { Avatar, Menu, Typography } from "antd"
import { useRequest } from "../../../../hooks"

const routes = [
  {
    name: "Articles",
    route: "/articles",
  },
  {
    name: "Authors",
    route: "/authors",
  },
  {
    name: "Graph",
    route: "/graph",
  },
]

const ArticleListNavbar = () => {
  const { articleListId } = useParams()
  const { pathname } = useLocation()
  return (
    <div className="flex justify-around items-center h-full mr-4 pr-4">
      <Menu
        theme="light"
        className="bg-transparent"
        mode="horizontal"
        selectedKeys={[pathname]}
      >
        {routes.map(({ name, route }) => (
          <Menu.Item key={`/articleLists/${articleListId}${route}`}>
            {name}
            <Link to={`/articleLists/${articleListId}${route}`} />
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )
}

export default ArticleListNavbar
