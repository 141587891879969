export const centralityList = [
  {
    title: "Eigenvector Centrality",
    key: "eigenvector",
  },
  {
    title: "Betweenness Centrality",
    key: "betweenness",
  },
  {
    title: "Closeness Centrality",
    key: "closeness",
  },
  {
    title: "Degree Centrality",
    key: "degree",
  },
]

export const influencePresetList = [
  {
    title: "Only Eigen",
    key: "eigen",
    weights: {
      eigenvector: 1,
      betweenness: 0,
      closeness: 0,
      degree: 0,
    },
  },
  {
    title: "Btw & Closeness",
    key: "btw-close",
    weights: {
      eigenvector: 0,
      betweenness: 0.5,
      closeness: 0.5,
      degree: 0,
    },
  },
  {
    title: "Mixed Equal",
    key: "mix-eql",
    weights: {
      eigenvector: 0.25,
      betweenness: 0.25,
      closeness: 0.25,
      degree: 0.25,
    },
  },
  {
    title: "Cool Mixture",
    key: "cool-mix",
    weights: {
      eigenvector: 0.4,
      betweenness: 0.25,
      closeness: 0.25,
      degree: 0.1,
    },
  },
  {
    title: "Custom",
    key: "custom",
  },
]

export const influencePresetByKey = influencePresetList.reduce(
  (acc, preset) => ({
    ...acc,
    [preset.key]: preset,
  }),
  {}
)
