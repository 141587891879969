import React, { useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Row, Button, Col, Input, InputNumber, DatePicker } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

const defaultDate = moment("2016-01-01")

const DateFilter = ({ value, onChange }) => {
  const changeBy = (num, type) => () => {
    if (!value) {
      return
    }
    onChange(value.clone().add(num, type))
  }

  return (
    <div className="flex flex-col">
      <Input.Group compact className="flex">
        <Button
          icon={<LeftOutlined />}
          className="w-4"
          disabled={!value}
          onClick={changeBy(-1, "y")}
        />
        <DatePicker
          value={value}
          onChange={onChange}
          picker="year"
          fromat="YYYY"
          placeholder="year"
          suffixIcon={null}
          className="flex-1 pl-2 pr-1"
          allowClear={false}
          defaultPickerValue={defaultDate}
        />
        <Button
          icon={<RightOutlined />}
          className="w-4"
          disabled={!value}
          onClick={changeBy(1, "y")}
        />
      </Input.Group>
      <Input.Group compact className="flex">
        <Button
          icon={<LeftOutlined />}
          className="w-4"
          disabled={!value}
          onClick={changeBy(-1, "Q")}
        />
        <DatePicker
          value={value}
          onChange={onChange}
          picker="quarter"
          format="[Q]Q"
          placeholder="QQ"
          suffixIcon={null}
          className="flex-1"
          allowClear={false}
        />
        <Button
          icon={<RightOutlined />}
          className="w-4"
          disabled={!value}
          onClick={changeBy(1, "Q")}
        />
      </Input.Group>
      <Input.Group compact className="flex">
        <Button
          icon={<LeftOutlined />}
          className="w-4"
          disabled={!value}
          onClick={changeBy(-1, "M")}
        />
        <DatePicker
          value={value}
          onChange={onChange}
          picker="month"
          format="[M-]MM"
          placeholder="month"
          suffixIcon={null}
          className="flex-1 pl-1 pr-0"
          allowClear={false}
        />
        <Button
          icon={<RightOutlined />}
          className="w-4"
          disabled={!value}
          onClick={changeBy(1, "M")}
        />
      </Input.Group>
      <Button danger disabled={!value} onClick={() => onChange(undefined)}>
        Clear
      </Button>
    </div>
  )
}

DateFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

DateFilter.defaultProps = {
  value: undefined,
}

export default DateFilter
