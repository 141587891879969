import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import debounce from "lodash.debounce"
import throttle from "lodash.throttle"
import { cypherviewer } from "popoto"
import { useNeo4jQuery, useRequest } from "../../../../hooks"
import { getArticleStatus } from "./helpers"
import { doilistCypherQuery } from "../../Sigma/constants"
import { influencePresetList } from "./metrics"
import { getColorString } from "./colors"
import { COAUTHORSHIP_MODES } from "./constants"

const ArticleListContext = React.createContext({
  articleDois: [],
  authorIds: [],
  articlesByDoi: {},
  authorsById: {},
  requests: {},
  options: {},
  helpers: {},
})
export const useArticleListContext = () => useContext(ArticleListContext)

const getCompressedClusterNo = (() => {
  let lastClusterNo = 0
  const clusterRegistry = new Map()
  return (cluster) => {
    if (!clusterRegistry.has(cluster)) {
      clusterRegistry.set(cluster, lastClusterNo)
      // eslint-disable-next-line no-plusplus
      ++lastClusterNo
    }
    return clusterRegistry.get(cluster)
  }
})()

const ArticleListProvider = ({ children }) => {
  const { articleListId } = useParams()
  const [articleListState, articleListRetry] = useRequest({
    url: `article/articleList/${articleListId}`,
    method: "GET",
    resultMapper: ({ articles, public: isPublic, title, ...rest }) => ({
      ...rest,
      title,
      public: isPublic,
      articleDois: articles.map(({ doi }) => doi),
      articlesByDoi: articles.reduce(
        (acc, article) => ({
          ...acc,
          [article.doi]: {
            doi: article.doi,
            status: getArticleStatus(article),
          },
        }),
        {}
      ),
    }),
  })

  const articleDois = useMemo(() => {
    return (articleListState.result || {}).articleDois || []
  }, [articleListState.result])
  /* eslint-enable max-len */
  const [coauthorshipMode, setCoauthorshipMode] = useState(
    COAUTHORSHIP_MODES.ARTICLE_CENTRIC
  )
  const [publicationDateFilter, setPublicationDateFilter] = useState({})
  const coauthorshipBody = useMemo(() => {
    const body = {
      doiList: articleDois,
      coauthorshipMode,
    }
    const { start, end } = publicationDateFilter
    if (start) {
      body.pubDateFromMonth = start.month() + 1
      body.pubDateFromYear = start.year()
    }
    if (end) {
      body.pubDateToMonth = end.month() + 1
      body.pubDateToYear = end.year()
    }
    return body
  }, [articleDois, coauthorshipMode, publicationDateFilter])
  const [coauthorshipQueryState, coauthorshipQueryRetry] = useRequest({
    url: `graph/graph/coauthorship`,
    method: "POST",
    body: coauthorshipBody,
    enabled: articleListState.loaded,
    resultMapper: ({ authors, coauthorRelationships }) => {
      let maxDegree = 1
      const maxBetweennessByCluster = {}
      authors.forEach(({ degree, betweenness, cluster }) => {
        maxDegree = Math.max(maxDegree, degree)
        maxBetweennessByCluster[cluster] = Math.max(
          maxBetweennessByCluster[cluster] || 1,
          betweenness
        )
      })
      return {
        coauthorRelationships,
        authors: authors.map(
          ({ nodeId, degree, betweenness, cluster, ...rest }) => ({
            ...rest,
            nodeId,
            degree: degree / maxDegree,
            degreeRaw: degree,
            betweenness: betweenness / maxBetweennessByCluster[cluster],
            betweennessRaw: betweenness,
            cluster: getCompressedClusterNo(cluster),
            clusterRaw: cluster,
          })
        ),
      }
    },
  })
  const articleAuthorBody = useMemo(() => {
    return {
      doiList: articleDois,
    }
  }, [articleDois])
  const [articleQueryState, articleQueryRetry] = useRequest({
    url: `graph/graph/articles`,
    method: "POST",
    body: articleAuthorBody,
    enabled: articleListState.loaded,
  })
  const [authorQueryState, authorQueryRetry] = useRequest({
    url: `graph/graph/authors`,
    method: "POST",
    body: articleAuthorBody,
    enabled: articleListState.loaded,
  })
  /* const coauthorshipQuery = useMemo(
    () => doilistCypherQuery(articleDois, { coauthorshipMode }),
    [articleDois, coauthorshipMode]
  ) */
  /* const [articleQueryState, articleQueryRetry] = useNeo4jQuery({
    enabled: articleListState.loaded,
    query: articleQuery,
    parameters: queryParams,
  })
  const [authorQueryState, authorQueryRetry] = useNeo4jQuery({
    enabled: articleListState.loaded,
    query: authorQuery,
    parameters: queryParams,
  }) */
  /* const [coauthorshipQueryState, coauthorshipQueryRetry] = useNeo4jQuery({
    enabled: articleListState.loaded,
    query: coauthorshipQuery,
  }) */
  const articlesByDoi = useMemo(() => {
    const { articles = [] } = articleQueryState.result || {}
    return articles.reduce((acc, { doi, title, authors, ...rest }) => {
      const authorIdsOfArticle = [...authors]
        .sort((a, b) => a.order - b.order)
        .map(({ id }) => id)
      return {
        ...acc,
        [doi]: {
          ...rest,
          ...acc[doi],
          doi,
          title,
          authorIds: authorIdsOfArticle,
        },
      }
    }, (articleListState.result || {}).articlesByDoi || {})
  }, [articleListState.result, articleQueryState.result])
  const authorIds = useMemo(() => {
    const { authors = [] } = authorQueryState.result || {}
    const { authors: authorList = [] } = coauthorshipQueryState.result || {}
    const authorIdSet = new Set([
      ...authors.map(({ id }) => id),
      ...authorList.map(({ nodeId }) => nodeId),
    ])
    return Array.from(authorIdSet).sort()
  }, [authorQueryState.result, coauthorshipQueryState.result])
  /* GRAPH FILTER / OPTIONS */
  const [influencePreset, setInfluencePreset] = useState(influencePresetList[0])
  const throttledSetInfluencePreset = useMemo(
    () => debounce(setInfluencePreset, 100),
    [setInfluencePreset]
  )
  const [clusterColorOptions, setClusterColorOptions] = useState({
    isColorblind: false,
    colorSchemeIndex: 1,
  })
  const throttledSetColorOptions = useMemo(
    () => debounce(setClusterColorOptions, 100),
    [setClusterColorOptions]
  )
  const getClusterColor = useMemo(
    () =>
      getColorString(clusterColorOptions)(clusterColorOptions.colorSchemeIndex),
    [clusterColorOptions.isColorblind, clusterColorOptions.colorSchemeIndex]
  )
  const getInfluence = useMemo(
    () => (node) =>
      Object.entries(influencePreset.weights).reduce(
        (total, [key, weight]) => total + (node[key] || 0) * weight,
        0
      ),
    [influencePreset.weights]
  )
  const helpers = useMemo(
    () => ({
      getClusterColor,
      getInfluence,
    }),
    [getClusterColor, getInfluence]
  )

  const [authorsById, setAuthorsById] = useState({})

  useEffect(() => {
    const { authors = [] } = authorQueryState.result || {}
    const { authors: authorList = [] } = coauthorshipQueryState.result || {}
    setAuthorsById(
      authorList
        .map((author) => ({
          ...author,
          influence: getInfluence(author),
        }))
        .reduce(
          (acc, author) => ({
            ...acc,
            [author.nodeId]: {
              ...acc[author.nodeId],
              id: author.nodeId,
              ...author,
            },
          }),
          authors.reduce(
            (acc, { id, firstName, lastName, articles, affiliations }) => {
              const articleDoisOfAuthor = [...articles]
                .sort((a, b) => a.order - b.order)
                .map(({ doi }) => doi)
              const affiliationsOfAuthor = affiliations.map(
                ({ affiliation, city, country }) => ({
                  affiliation,
                  city,
                  country,
                })
              )
              return {
                ...acc,
                [id]: {
                  ...((authorsById || {})[id] || {}),
                  id,
                  firstName,
                  lastName,
                  affiliations: affiliationsOfAuthor,
                  articleDois: articleDoisOfAuthor,
                },
              }
            },
            {}
          )
        )
    )
  }, [authorQueryState.result, coauthorshipQueryState.result, getInfluence])

  const requests = useMemo(
    () => ({
      articleList: {
        state: articleListState,
        retry: articleListRetry,
      },
      authorQuery: {
        state: authorQueryState,
        retry: authorQueryRetry,
      },
      articleQuery: {
        state: articleQueryState,
        retry: articleQueryRetry,
      },
      coauthorshipQuery: {
        state: coauthorshipQueryState,
        retry: coauthorshipQueryRetry,
      },
    }),
    [
      articleListState,
      authorQueryState,
      articleQueryState,
      coauthorshipQueryState,
    ]
  )

  const options = useMemo(
    () => ({
      influencePreset: {
        state: influencePreset,
        setState: throttledSetInfluencePreset,
      },
      clusterColor: {
        state: clusterColorOptions,
        setState: throttledSetColorOptions,
      },
      coauthorshipMode: {
        state: coauthorshipMode,
        setState: setCoauthorshipMode,
      },
      publicationDateFilter: {
        state: publicationDateFilter,
        setState: setPublicationDateFilter,
      },
    }),
    [
      influencePreset,
      clusterColorOptions,
      coauthorshipMode,
      publicationDateFilter,
    ]
  )

  const contextValue = useMemo(
    () => ({
      articleDois,
      authorIds,
      articlesByDoi,
      authorsById,
      requests,
      options,
      helpers,

      /* articleListState,
      articleListRetry,
      authorQueryState,
      authorQueryRetry,
      articleQueryState,
      articleQueryRetry,
      coauthorshipQueryState,
      coauthorshipQueryRetry, */
    }),
    [
      requests,
      options,
      helpers,
      articleDois,
      authorIds,
      authorsById,
      articlesByDoi,
      influencePreset,
    ]
  )
  // console.log("context", contextValue)
  return (
    <ArticleListContext.Provider value={contextValue}>
      {children}
    </ArticleListContext.Provider>
  )
}

ArticleListProvider.propTypes = {
  children: PropTypes.node,
}
ArticleListProvider.defaultProps = {
  children: null,
}

export default ArticleListProvider

export const withArticleListProvider = (Component) => (...props) => (
  <ArticleListProvider>
    <Component {...props} />
  </ArticleListProvider>
)
