import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { startForceLink, stopForceLink } from "./plugins/forceLink"
import { startDragNodes, stopDragNodes } from "./plugins/dragNodes"

const SigmaPlugins = ({ sigma, children }) => {
  const prevSigma = useRef()
  useEffect(() => {
    console.log("sigma", sigma)
    if (prevSigma.current !== sigma) {
      if (prevSigma.current) {
        stopDragNodes(sigma)
        stopForceLink(sigma)
      }
      if (sigma) {
        window.sigmaInstance = sigma
        startDragNodes(sigma)
        startForceLink(sigma)
      }
    }
  }, [sigma])
  return children /* (
    <div>
      <pre>{JSON.stringify(sigma, null, 2)}</pre>
      <pre>{JSON.stringify(nodes, null, 2)}</pre>
      <pre>{JSON.stringify(edges, null, 2)}</pre>
    </div>
  ) */
}

SigmaPlugins.propTypes = {
  children: PropTypes.node,
}

SigmaPlugins.defaultProps = {
  children: null,
}

export default SigmaPlugins
