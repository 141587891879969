export const Article = {
  strings: ["doi", "title"],
  numbers: [],
}

export const Author = {
  strings: ["lastName", "firstName", "authorId"],
  numbers: [
    "betweennessCentrality",
    "closenessCentrality",
    "eigenvectorCentrality",
    "degree",
  ],
}

export const Affiliation = {
  strings: ["affiliationName", "affiliationId"],
  numbers: [],
}

export const City = {
  strings: ["name"],
  numbers: [],
}

export const Country = {
  strings: ["name"],
  numbers: [],
}

export const Topic = {
  strings: ["title"],
  numbers: [],
}

export const keys = [
  "Article",
  "Author",
  "Affiliation",
  "City",
  "Country",
  "Topic",
]
