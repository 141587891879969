import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Tag,
  Tooltip,
  Row,
  Col,
  Alert,
} from "antd"
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  LockFilled,
  ReloadOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { useRequest } from "../../../../hooks"
import { ARTICLE } from "../../../../constants"
import Loading from "../../../../components/Loading"
import { getArticleStatus } from "./helpers"
import {
  ARTICLE_STATUS_COLOR,
  ARTICLE_STATUS_ICON_RENDER,
  ARTICLE_STATUS_TEXT,
  ARTICLE_STATUSES,
} from "./constants"
import { useAuth0 } from "../../../../modules/auth0"

const tagRender = (props) => {
  const { label, closable, onClose } = props
  let color
  let renderIcon = () => null
  let value
  let tooltip
  if (typeof label === "string") {
    color = "default"
    value = label
    tooltip = "Not submitted"
  } else {
    const status = getArticleStatus(label)
    value = label.doi
    tooltip = ARTICLE_STATUS_TEXT[status]
    color = ARTICLE_STATUS_COLOR[status]
    renderIcon = ARTICLE_STATUS_ICON_RENDER[status]
  }
  return (
    <Tooltip placement="top" title={tooltip}>
      <Tag
        color={color}
        icon={renderIcon()}
        closable={closable}
        onClose={onClose}
      >
        {value}
      </Tag>
    </Tooltip>
  )
}

tagRender.propTypes = {
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      status: PropTypes.number.isRequired,
      crawler: PropTypes.number.isRequired,
      doi: PropTypes.string.isRequired,
    }),
  ]).isRequired,
}

const ArticleListForm = ({
  initialValues,
  onSubmitSuccess,
  onSubmitError,
  isNew,
  articleListId,
}) => {
  const [submitData, setSubmitData] = useState(null)
  const [{ loading, loaded, error, result }, retry] = useRequest({
    url: isNew ? `article/articleList` : `article/articleList/${articleListId}`,
    method: isNew ? "POST" : "PUT",
    enabled: !!submitData,
    body: submitData,
  })
  const { user, logout } = useAuth0()

  useEffect(() => {
    if (loaded && !loading && onSubmitSuccess) {
      onSubmitSuccess(result)
    }
    if (!loaded && !loading && error && onSubmitError) {
      onSubmitError(error)
    }
  }, [loading, loaded, error])

  const handleSubmit = ({ title, public: isPublic, articles }) => {
    setSubmitData({
      title,
      public: isPublic || false,
      articles: (articles || []).map(({ value }) => value),
      authorName: user.nickname || "",
    })
  }

  return (
    <Form
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={{ span: 19 }} sm={{ span: 21 }}>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please enter title" }]}
          >
            <Input placeholder="Please enter a title." />
          </Form.Item>
        </Col>
        <Col xs={{ span: 5 }} sm={{ span: 3 }}>
          <Form.Item label="Public" name="public" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Articles (DOIs)" name="articles">
        <Select
          tagRender={tagRender}
          mode="tags"
          tokenSeparators={[",", " ", "'", '"', "[", "]"]}
          style={{ width: "100%" }}
          labelInValue
          dropdownClassName="hidden"
          optionLabelProp="value"
          placeholder="Enter / Paste DOIs of articles"
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          type="primary"
          htmlType="submit"
          className="h-auto"
          loading={loading}
        >
          {isNew ? "Save" : "Update"}
        </Button>
      </Form.Item>
      {error ? (
        <Alert
          showIcon
          closable={false}
          className="mt-8"
          type="error"
          message={error}
        />
      ) : null}
    </Form>
  )
}

ArticleListForm.propTypes = {
  initialValues: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    public: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  }),
  articleListId: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
  isNew: PropTypes.bool,
}
ArticleListForm.defaultProps = {
  initialValues: null,
  articleListId: "",
  onSubmitSuccess: undefined,
  onSubmitError: undefined,
  isNew: false,
}

const ArticleListModal = ({
  articleListId: nextArticleListId,
  visible,
  onClose,
  onSuccess,
}) => {
  const [articleListId, setArticleListId] = useState(nextArticleListId)
  useEffect(() => {
    if (visible) {
      // articleListId changes and visible is on
      setArticleListId(nextArticleListId)
    } else {
      // visible is set as off, change articleListId after animation delay
      setTimeout(() => {
        setArticleListId(articleListId)
      }, 250)
    }
  }, [nextArticleListId, visible])
  const isNew = !articleListId
  const [{ loading, loaded, error, result }, retry] = useRequest({
    url: `article/articleList/${articleListId}`,
    method: "GET",
    enabled: !isNew,
    resultMapper: ({ articles, public: isPublic, title }) => ({
      title,
      public: isPublic,
      articles: articles.map((article) => ({
        value: article.doi,
        label: article,
      })),
    }),
  })

  const handleSuccess = (response) => {
    onClose()
    if (onSuccess) {
      onSuccess(response)
    }
  }

  return (
    <Modal
      visible={visible}
      title={
        isNew
          ? "New Article List"
          : `Edit Article List, ${(result || {}).title || ""}`
      }
      onCancel={() => onClose()}
      footer={null}
      width={720}
    >
      {isNew ? (
        <ArticleListForm onSubmitSuccess={handleSuccess} isNew />
      ) : (
        <Loading loading={loading} loaded={loaded} retry={retry} error={error}>
          <ArticleListForm
            onSubmitSuccess={handleSuccess}
            initialValues={result}
            articleListId={articleListId}
          />
        </Loading>
      )}
    </Modal>
  )
}

ArticleListModal.propTypes = {
  articleListId: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}
ArticleListModal.defaultProps = {
  articleListId: "",
  visible: false,
  onSuccess: undefined,
}

export default ArticleListModal
