import React from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { Layout, Menu } from "antd"

import Home from "./Home"

const UserRoutes = () => {
  const location = useLocation()
  return (
    <Layout className="layout">
      <Layout.Content>
        <Switch>
          <Route exact path="/" component={Home} />
          <Redirect
            to={{
              pathname: "/",
              state: { from: location.pathname },
            }}
          />
        </Switch>
      </Layout.Content>
    </Layout>
  )
}

const styles = {
  header: {},
}

export default UserRoutes
