export default [
  "10.1109/comst.2016.2527741",
  "10.1109/lcomm.2014.2320917",
  "10.1109/jsac.2016.2525538",
  "10.1109/mwc.2016.7553035",
  "10.1049/el.2014.2943",
  "10.1109/lcomm.2014.2375214",
  "10.1109/spawc.2017.8227765",
  "10.1109/infcomw.2015.7179319",
  "10.1109/mwc.2017.1600308",
  "10.1109/lwc.2017.2662689",
  "10.1109/iccw.2013.6649341",
  "10.1109/wcnc.2017.7925961",
  "10.1109/icc.2015.7248473",
  "10.1109/tcomm.2020.2977282",
  "10.1109/tcomm.2018.2865732",
  "10.1109/icc.2016.7511447",
  "10.1109/access.2018.2875925",
  "10.1109/icc.2019.8761827",
  "10.1109/lcomm.2018.2871456",
  "10.1109/tnb.2019.2900466",
  "10.1109/icc.2019.8761812",
  "10.1109/access.2019.2922294",
  "10.1016/j.nancom.2018.10.001",
  "10.1109/jsen.2019.2933222",
  "10.1109/jproc.2019.2927926",
  "10.1109/jproc.2019.2913890",
  "10.1016/j.nancom.2017.01.004",
  "10.1109/tnb.2016.2627081",
  "10.1109/tbme.2015.2430011",
  "10.1109/tcomm.2019.2959354",
  "10.1016/j.nancom.2019.100280",
  "10.1109/tmbmc.2019.2937297",
  "10.1016/j.nancom.2019.02.001",
  "10.1109/tmbmc.2018.2887237",
  "10.1109/tmbmc.2018.2885288",
  "10.1145/3233188.3233216",
  "10.1109/tit.2012.2193554",
  "10.1371/journal.pone.0082935",
  "10.1109/jsac.2014.2367662",
  "10.1109/tnb.2014.2342259",
  "10.1109/glocom.2015.7417583",
  "10.1109/tnb.2013.2295546",
  "10.1109/tmbmc.2015.2501741",
  "10.1109/tmbmc.2016.2589239",
  "10.1109/tmbmc.2015.2465511",
  "10.1109/lcomm.2017.2678467",
  "10.1109/jsac.2014.2367693",
  "10.1016/j.nancom.2017.02.002",
  "10.1016/j.nancom.2017.05.001",
  "10.1109/tnb.2019.2891238",
]
