import React from "react"
import PropTypes from "prop-types"
import { embedProps } from "react-sigma/lib/tools"
import useSigmaNodeEdgeUpdatesLight from "../../hooks/private/updateSigmaNodesEdgesLight"

const SigmaDynamicNodesEdges = ({ sigma, nodes, edges, children }) => {
  /* console.log("siigma", sigma) */
  useSigmaNodeEdgeUpdatesLight(sigma, nodes, edges)
  return embedProps(children, {
    sigma,
  }) /* (
    <div>
      <pre>{JSON.stringify(sigma, null, 2)}</pre>
      <pre>{JSON.stringify(nodes, null, 2)}</pre>
      <pre>{JSON.stringify(edges, null, 2)}</pre>
    </div>
  ) */
}

SigmaDynamicNodesEdges.propTypes = {
  children: PropTypes.node,
}

SigmaDynamicNodesEdges.defaultProps = {
  children: null,
}

export default SigmaDynamicNodesEdges
