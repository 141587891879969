/* global sigma */
import "react-sigma/sigma/layout.forceLink"

const options = {
  linLogMode: true,
  worker: true,
}

let runningForceLink = false
export const startForceLink = (s) => {
  if (!runningForceLink) {
    runningForceLink = true
    sigma.layouts.configForceLink(s, options)
    sigma.layouts.startForceLink(s)
    console.log("poop", sigma.layouts)
  }
}
export const stopForceLink = () => {
  if (runningForceLink) {
    runningForceLink = false
    sigma.layouts.stopForceLink()
  }
}
