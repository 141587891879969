import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  Form,
  Menu,
  Modal,
  Select,
  Typography,
  Collapse,
  Slider,
  Layout,
  DatePicker,
  Row,
  Col,
} from "antd"

import { CenterView } from "../../../../layouts"
import { Neo4JHelper } from "../../../../helpers"
import {
  influencePresetByKey,
  influencePresetList,
  centralityList,
} from "./metrics"
import { useArticleListContext } from "./Context"
import { COAUTHORSHIP_MODE_LABEL, COAUTHORSHIP_MODE_LIST } from "./constants"
import DateFilter from "./DateFilter"

const Sidebar = () => {
  const { options } = useArticleListContext()
  const { state: contextInfluencePreset, setState: setContextInfluencePreset } =
    options.influencePreset || {}
  const { state: coauthorshipMode, setState: setCoauthorshipMode } =
    options.coauthorshipMode || {}
  const { state: publicationDateFilter, setState: setPublicationDateFilter } =
    options.publicationDateFilter || {}
  const [collapsed, setCollapsed] = useState(false)
  const [realCollapsed, setRealCollapsed] = useState(false)
  const [contentHidden, setContentHidden] = useState(false)
  const [panelHeaderHidden, setPanelHeaderHidden] = useState(false)
  const [influencePreset, setInfluencePreset] = useState(contextInfluencePreset)
  const [accordionKey, setAccordionKey] = useState()
  useEffect(() => {
    // MAKE SIDEBAR COLLAPSE SMOOTH
    if (collapsed) {
      setTimeout(() => {
        setRealCollapsed(true)
        setPanelHeaderHidden(true)
      }, 150)
      setContentHidden(true)
    } else {
      setRealCollapsed(false)
      setTimeout(() => {
        setPanelHeaderHidden(false)
        setContentHidden(false)
      }, 150)
    }
  }, [collapsed])
  const handlePresetChange = (presetKey) => {
    let nextPreset = influencePresetByKey[presetKey]
    if (presetKey === "custom") {
      nextPreset = { ...nextPreset, weights: influencePreset.weights }
    }
    setInfluencePreset(nextPreset)
    setContextInfluencePreset(nextPreset)
  }
  const handleCentralityWeightChange = (propertyName) => (value) => {
    const prevWeights = influencePreset.weights
    const nextWeights = { ...prevWeights, [propertyName]: value }
    let difference = value - prevWeights[propertyName]
    const bound =
      difference > 0
        ? (diffPerRemaining) => (x) => {
            // eslint-disable-next-line no-param-reassign
            x.value -= diffPerRemaining
            if (x.value < 0) {
              difference -= x.value
              // eslint-disable-next-line no-param-reassign,no-multi-assign
              nextWeights[x.key] = x.value = 0
            }
          }
        : (diffPerRemaining) => (x) => {
            // eslint-disable-next-line no-param-reassign
            x.value -= diffPerRemaining
            if (x.value > 1) {
              difference -= x.value - 1
              // eslint-disable-next-line no-param-reassign,no-multi-assign
              nextWeights[x.key] = x.value = 1
            }
          }
    const isNotBoundary =
      difference > 0 ? (x) => x.value !== 0 : (x) => x.value !== 1
    let remainingList = centralityList
      .filter(({ key }) => key !== propertyName)
      .map(({ key }) => ({ key, value: prevWeights[key] }))
    do {
      const diffPerRemaining = difference / remainingList.length
      difference = 0
      remainingList.forEach(bound(diffPerRemaining))
      remainingList = remainingList.filter(isNotBoundary)
    } while (difference !== 0)
    remainingList.forEach((x) => {
      nextWeights[x.key] = x.value
    })
    const nextPreset = {
      ...influencePreset,
      weights: nextWeights,
    }
    setInfluencePreset(nextPreset)
    setContextInfluencePreset(nextPreset)
  }
  return (
    <Layout.Sider
      className="text-gray-100"
      collapsible
      reverseArrow
      collapsedWidth={32}
      collapsed={realCollapsed}
      onCollapse={setCollapsed}
    >
      <Form layout="vertical">
        <Collapse
          accordion
          activeKey={contentHidden ? undefined : accordionKey}
          onChange={setAccordionKey}
        >
          <Collapse.Panel
            header={panelHeaderHidden ? "Co-authorship" : "Co-authorship Mode"}
            key="1"
          >
            <Form.Item label="Preset">
              <Select value={coauthorshipMode} onChange={setCoauthorshipMode}>
                {COAUTHORSHIP_MODE_LIST.map((key) => (
                  <Select.Option value={key} key={key}>
                    {COAUTHORSHIP_MODE_LABEL[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            header={panelHeaderHidden ? "Influence" : "Influence Metrics"}
            key="2"
          >
            <Form.Item label="Preset">
              <Select value={influencePreset.key} onChange={handlePresetChange}>
                {influencePresetList.map(({ key, title }) => (
                  <Select.Option value={key} key={key}>
                    {title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {centralityList.map(({ title, key }) => (
              <Form.Item
                key={key}
                label={title}
                className="my-1 no-label-padding"
              >
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={handleCentralityWeightChange(key)}
                  value={influencePreset.weights[key]}
                  disabled={influencePreset.key !== "custom"}
                  className="mx-0"
                />
              </Form.Item>
            ))}
          </Collapse.Panel>
          {/* <Collapse.Panel
            header={panelHeaderHidden ? "Cluster" : "Clustering Options"}
            key="3"
          >
            <p>Todo :(</p>
          </Collapse.Panel> */}
          <Collapse.Panel
            header={panelHeaderHidden ? "Publication" : "Publication Date"}
            key="4"
          >
            <Row className="-mx-2" gutter={8}>
              <Col span={12}>
                <Form.Item label="Start Date">
                  <DateFilter
                    onChange={(start) => {
                      setPublicationDateFilter({
                        ...publicationDateFilter,
                        start,
                      })
                    }}
                    value={publicationDateFilter.start}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="End Date">
                  <DateFilter
                    onChange={(end) => {
                      setPublicationDateFilter({
                        ...publicationDateFilter,
                        end,
                      })
                    }}
                    value={publicationDateFilter.end}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Layout.Sider>
  )
}

export default Sidebar
