import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
// import ForceGraph2D from "react-force-graph-2d"
import ForceGraph3D from "react-force-graph-3d"
import SpriteText from "three-spritetext"
import { forceX, forceY, forceZ } from "d3-force-3d"
import { withContentRect } from "react-measure"
import { useRequest } from "../../../../hooks"
import Loading from "../../../../components/Loading"

const CENTER_FORCE_STRENGTH = 0.04
const nodeRelSize = 2

const nameInitial = (name) =>
  name ? `${name.substr(0, 1).toUpperCase()}.` : ""

const ForceGraph = ({
  coauthorshipQueryState,
  coauthorshipQueryRetry,
  getNodeSize,
  getNodeLabel,
  getNodeColor,
  measureRef,
  contentRect,
}) => {
  const nodeMap = useRef(new Map())
  const linkMap = useRef(new Map())
  const [graph, setGraph] = useState({ nodes: [], links: [] })

  const { loading, loaded, error, result } = coauthorshipQueryState

  useEffect(() => {
    if (!loading && !error && loaded) {
      const { authors, coauthorRelationships } = result
      const nextNodeMap = new Map()
      const nextLinkMap = new Map()
      authors.forEach((author) => {
        if (nodeMap.current.has(author.nodeId)) {
          nextNodeMap.set(author.nodeId, nodeMap.current.get(author.nodeId))
          Object.entries(author).forEach(([key, value]) => {
            nextNodeMap.get(author.nodeId)[key] = value
          })
        } else {
          nextNodeMap.set(author.nodeId, { ...author })
        }
      })
      coauthorRelationships.forEach((relationship) => {
        const id = `${relationship.source}-${relationship.target}`
        if (linkMap.current.has(id)) {
          nextLinkMap.set(id, linkMap.current.get(id))
        } else {
          nextLinkMap.set(id, { ...relationship })
        }
      })
      nodeMap.current = nextNodeMap
      linkMap.current = nextLinkMap

      setGraph({
        nodes: Array.from(nextNodeMap.values()),
        links: Array.from(nextLinkMap.values()),
      })
      /* setGraph({
        nodes: authors,
        links: coauthorRelationships,
      }) */
    }
  }, [loading, loaded, error, result])

  const nodeCanvasObject = useMemo(() => {
    return (node, ctx, globalScale) => {
      const r = Math.sqrt(Math.max(0, getNodeSize(node) || 1)) * nodeRelSize
      const label = `${nameInitial(node.firstName)}${nameInitial(
        node.lastName
      )}`
      const fontSize = r * 0.8
      ctx.font = `500 ${fontSize}px Sans-Serif`

      ctx.shadowColor = "black"
      ctx.shadowBlur = fontSize * 0.1

      ctx.textAlign = "center"
      ctx.textBaseline = "middle"

      ctx.fillStyle = "#000000"
      ctx.fillText(label, node.x, node.y)
    }
  }, [getNodeSize])

  const nodeThreeObject = useMemo(() => {
    return (node) => {
      const sprite = new SpriteText(node.id)
      sprite.color = node.color
      sprite.textHeight = 8
      return sprite
    }
  }, [getNodeSize])

  return (
    <Loading
      loaded={loaded}
      loading={loading}
      error={error}
      retry={coauthorshipQueryRetry}
      overlay
      size="xlarge"
      containerRef={measureRef}
    >
      <ForceGraph3D
        width={contentRect.client.width || 100}
        height={contentRect.client.height || 100}
        graphData={graph}
        nodeId="nodeId"
        nodeLabel={getNodeLabel}
        nodeVal={getNodeSize}
        nodeColor={getNodeColor}
        nodeRelSize={nodeRelSize}
      />
    </Loading>
  )
}

ForceGraph.propTypes = {
  coauthorshipQueryState: useRequest.createPropType(
    PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          eigenvector: PropTypes.number.isRequired,
          betweenness: PropTypes.number.isRequired,
          closeness: PropTypes.number.isRequired,
          degree: PropTypes.number.isRequired,
        })
      ).isRequired,
      coauthorRelationships: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          target: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  coauthorshipQueryRetry: PropTypes.func.isRequired,
  getNodeSize: PropTypes.func,
  getNodeLabel: PropTypes.func,
  getNodeColor: PropTypes.func,
  measureRef: PropTypes.func.isRequired,
  contentRect: PropTypes.shape({
    client: {
      clientWidth: PropTypes.number,
      clientHeight: PropTypes.number,
    },
  }).isRequired,
}

ForceGraph.defaultProps = {
  getNodeSize: (node) => node.closeness * node.eigenvector,
  getNodeLabel: ({ firstName, lastName }) =>
    `${(firstName || "").substr(0, 1)}.${lastName || ""}`,
  getNodeColor: (node) => "red",
}

const styles = {
  graph: {
    width: "100%",
    height: "100%",
  },
  btn: {
    position: "absolute",
    zIndex: 2,
  },
}

export default withContentRect("client")(ForceGraph)
