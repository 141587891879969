import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  Typography,
  Layout,
  Form,
  Input,
  Button,
  Select,
  Collapse,
} from "antd"

import ForceGraph from "./components/ForceGraph"
import ForceGraph3 from "./components/ForceGraph3"

import { doilistCypherQuery, initialDoiList } from "../Sigma/constants"
import { useNeo4jQuery, useRequest } from "../../../hooks"
import { COAUTHORSHIP_MODES } from "../ArticleLists/ArticleList/constants"

const ForceGraphPage = ({ is3d }) => {
  const [form] = Form.useForm()
  const [doiList, setDoiList] = useState(initialDoiList)

  /* const coauthorshipBody = useMemo(() => {
    const body = {
      doiList,
      coauthorshipMode: COAUTHORSHIP_MODES.ARTICLE_CENTRIC,
    }
    return body
  }, [doiList])
  const [coauthorshipQueryState, coauthorshipQueryRetry] = useRequest({
    url: `graph/graph/coauthorship`,
    method: "POST",
    body: coauthorshipBody,
    enabled: doiList.length > 0,
  }) */

  const coauthorshipQuery = useMemo(() => doilistCypherQuery(doiList), [
    doiList,
  ])
  const [coauthorshipQueryState, coauthorshipQueryRetry] = useNeo4jQuery({
    enabled: doiList.length > 0,
    query: coauthorshipQuery,
    resultMapper: (results) => results[0],
  })

  return (
    <Layout className="h-full">
      <Layout>
        <Layout.Header className="h-auto py-2 bg-red-200 px-4">
          <Collapse>
            <Collapse.Panel key="doi-list" header="View / Edit Doi List">
              <Form
                form={form}
                layout="inline"
                onFinish={(d) => setDoiList(d.doiList)}
                className="flex items-stretch content-center justify-center"
                initialValues={{
                  doiList: initialDoiList,
                }}
              >
                <Form.Item name="doiList" noStyle>
                  <Select mode="tags" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit" className="h-auto">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Layout.Header>
        <Layout.Content>
          {is3d ? (
            <ForceGraph3
              coauthorshipQueryState={coauthorshipQueryState}
              coauthorshipQueryRetry={coauthorshipQueryRetry}
            />
          ) : (
            <ForceGraph
              coauthorshipQueryState={coauthorshipQueryState}
              coauthorshipQueryRetry={coauthorshipQueryRetry}
            />
          )}
          {/* <NeovisGraph cypherQuery={cypherQuery} /> */}
        </Layout.Content>
      </Layout>
      <Layout.Sider theme="light">{/* <NeovisOptions /> */}</Layout.Sider>
    </Layout>
  )
}
ForceGraphPage.propTypes = {
  is3d: PropTypes.bool,
}
ForceGraphPage.defaultProps = {
  is3d: false,
}
export default ForceGraphPage
