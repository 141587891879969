import React from "react"
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons"

export const ARTICLE_STATUSES = {
  NEW: "NEW",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  NOT_FOUND: "NOT_FOUND",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
}

export const ARTICLE_STATUS_COLOR = {
  NEW: "purple",
  PROCESSING: "blue",
  SUCCESS: "green",
  NOT_FOUND: "orange",
  UNKNOWN_ERROR: "red",
}

export const ARTICLE_STATUS_TEXT = {
  NEW: "New / Waiting",
  PROCESSING: "Processing",
  SUCCESS: "Succeeded",
  NOT_FOUND: "Not Found",
  UNKNOWN_ERROR: "Unknown Error",
}

export const ARTICLE_STATUS_ORDER = {
  SUCCESS: 1,
  PROCESSING: 2,
  NEW: 3,
  NOT_FOUND: 4,
  UNKNOWN_ERROR: 5,
}

export const ARTICLE_STATUS_ICON_RENDER = {
  NEW: () => <ClockCircleOutlined />,
  PROCESSING: () => <SyncOutlined spin />,
  SUCCESS: () => <CheckCircleOutlined />,
  NOT_FOUND: () => <ExclamationCircleOutlined />,
  UNKNOWN_ERROR: () => <CloseCircleOutlined />,
}

export const COAUTHORSHIP_MODES = {
  ARTICLE_CENTRIC: "Article",
  FIRST_AUTHOR_CENTRIC: "First Author",
  LAST_AUTHOR_CENTRIC: "Last Author",
}

export const COAUTHORSHIP_MODE_LABEL = {
  [COAUTHORSHIP_MODES.ARTICLE_CENTRIC]: "Article Centric",
  [COAUTHORSHIP_MODES.FIRST_AUTHOR_CENTRIC]: "First Author Centric",
  [COAUTHORSHIP_MODES.LAST_AUTHOR_CENTRIC]: "Last Author Centric",
}

export const COAUTHORSHIP_MODE_LIST = [
  COAUTHORSHIP_MODES.ARTICLE_CENTRIC,
  COAUTHORSHIP_MODES.FIRST_AUTHOR_CENTRIC,
  COAUTHORSHIP_MODES.LAST_AUTHOR_CENTRIC,
]
