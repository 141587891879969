export const AUTHORED = {
  strings: [],
  numbers: ["order"],
}

export const AFFILIATED_WITH = {
  strings: [],
  numbers: [],
}

export const IS_IN_CITY = {
  strings: [],
  numbers: [],
}

export const IS_CITY_OF = {
  strings: [],
  numbers: [],
}

export const IS_TOPIC_OF = {
  strings: [],
  numbers: [],
}

export const keys = [
  "AUTHORED",
  "AFFILIATED_WITH",
  "IS_IN_CITY",
  "IS_CITY_OF",
  "IS_TOPIC_OF",
]
