import React from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { Layout, Menu } from "antd"

import { ColorsHelper } from "../../helpers"

import UserNavbar from "./Navbar"
import Home from "./Home"
import Popoto from "./Popoto"
import Neovis from "./Neovis"
import Sigma from "./Sigma"
import ArticleLists from "./ArticleLists"
import ArticleList from "./ArticleLists/ArticleList"
import ForceGraphPage from "./ForceGraph"

const UserRoutes = () => {
  const isLocal = document.location.hostname === "localhost"
  return (
    <Layout className="layout">
      <Layout.Header style={styles.header}>
        <UserNavbar />
      </Layout.Header>
      <Layout.Content>
        <Switch>
          <Route exact path="/" render={() => <ArticleLists latest />} />
          <Route exact path="/articleLists" component={ArticleLists} />
          <Route
            exact
            path="/articleLists/user/:userId"
            component={ArticleLists}
          />
          <Route path="/articleLists/:articleListId" component={ArticleList} />
          {isLocal ? <Route exact path="/popoto" component={Popoto} /> : null}
          {isLocal ? <Route exact path="/neovis" component={Neovis} /> : null}
          {isLocal ? <Route exact path="/sigma" component={Sigma} /> : null}
          {isLocal ? (
            <Route exact path="/force-graph" component={ForceGraphPage} />
          ) : null}
          <Redirect to="/" />
        </Switch>
      </Layout.Content>
    </Layout>
  )
}

const styles = {
  header: {},
}

export default UserRoutes
