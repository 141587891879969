import {
  RESET_NEOVIS_OPTIONS,
  UPDATE_NEOVIS_ENTITY,
  UPDATE_NEOVIS_RELATION,
} from "../../types"

export const updateNeovisEntity = (data) => ({
  type: UPDATE_NEOVIS_ENTITY,
  data,
})

export const updateNeovisRelation = (data) => ({
  type: UPDATE_NEOVIS_RELATION,
  data,
})

export const resetNeovisOptions = (data) => ({
  type: RESET_NEOVIS_OPTIONS,
  data,
})
