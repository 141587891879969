import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Avatar, Menu, Typography } from "antd"

import { ColorsHelper } from "../../../helpers"

import AppLogo from "../../../assets/images/app_icon.png"
import Neo4JModal from "../Neo4JModal"
import { useAuth0 } from "../../../modules/auth0"

const routes = [
  {
    name: "Home",
    route: "/",
  },
  {
    name: "My Article Lists",
    route: "/articleLists",
  },
]

const isLocal = document.location.hostname === "localhost"

if (isLocal) {
  routes.push(
    {
      name: "Neovis Test",
      route: "/neovis",
    },
    {
      name: "Popoto Test",
      route: "/popoto",
    },
    {
      name: "ForceGraph Test",
      route: "/force-graph",
    },
    {
      name: "Sigma Test",
      route: "/sigma",
    }
  )
}

const UserNavbar = () => {
  const { pathname } = useLocation()
  const { user = {}, logout } = useAuth0()
  return (
    <div className="flex justify-around items-center h-full overflow-x-auto">
      <Link to="/" style={styles.logo}>
        <img src={AppLogo} alt="sonar-logo" style={styles.logoImage} />
        <Typography.Title level={3} style={styles.logoTitle}>
          Sonar
        </Typography.Title>
      </Link>
      <Menu
        theme="dark"
        mode="horizontal"
        style={styles.menu}
        className="flex-1 flex"
        selectedKeys={[pathname]}
      >
        {routes.map(({ name, route }) => (
          <Menu.Item key={route}>
            {name}
            <Link to={route} />
          </Menu.Item>
        ))}
        {/* <Menu.SubMenu
         style={styles.profileSubmenu}
         title={
           <span className="submenu-title-wrapper">
              <Icon type="user" />
             { firstName } { lastName}
            </span>
         }
       >
         <Menu.Item key={`/users/${userId}`}>
           Profile
           <Link to={`/users/${userId}`} />
         </Menu.Item>
         <Menu.Item onClick={logOut}>
           Log Out
         </Menu.Item>
       </Menu.SubMenu> */}

        <div className="flex-1" />
        {isLocal ? (
          <Menu.Item>
            <Neo4JModal />
          </Menu.Item>
        ) : null}
        <Menu.SubMenu
          icon={<Avatar src={user.picture} className="mx-2" />}
          title={user.nickname}
        >
          <Menu.Item>
            <a href="#" onClick={logout}>
              Log Out
            </a>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </div>
  )
}

const styles = {
  logo: {
    /*
    height: "100%",
    float: "left", */
    display: "flex",
    alignItems: "center",
  },
  modal: {
    float: "right",
  },
  logoTitle: {
    color: "#fff",
    margin: "0 0 0 16px",
  },
  logoImage: {
    height: 36,
  },
  menu: {
    lineHeight: "64px",
    overflow: "auto",
    margin: "0 16px",
  },
  profileSubmenu: {
    float: "right",
  },
}

export default UserNavbar
