import update from "immutability-helper"
import {
  RESET_NEOVIS_OPTIONS,
  UPDATE_NEOVIS_ENTITY,
  UPDATE_NEOVIS_RELATION,
} from "../../types"
import { Neo4JHelper } from "../../../helpers"

const initialState = {
  labels: Neo4JHelper.Entities.keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        caption: Neo4JHelper.Entities[key].strings[0],
        size: Neo4JHelper.Entities[key].numbers[0] || 0.000001,
      },
    }),
    {}
  ),
  relationships: Neo4JHelper.Relations.keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        caption: Neo4JHelper.Relations[key].strings[0],
        thickness: Neo4JHelper.Relations[key].numbers[0] || 0.000001,
      },
    }),
    {}
  ),
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEOVIS_ENTITY:
      return update(state, {
        labels: {
          [action.data.key]: {
            $merge: action.data.value,
          },
        },
      })
    case UPDATE_NEOVIS_RELATION:
      return update(state, {
        relationships: {
          [action.data.key]: {
            $merge: action.data.value,
          },
        },
      })
    case RESET_NEOVIS_OPTIONS:
      return initialState
    default:
      return state
  }
}

export const selectNeovisLabels = (state) => state.neovis.labels
export const selectNeovisRelationships = (state) => state.neovis.relationships

export default reducer
