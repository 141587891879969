import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { selectNeo4j } from "../store/reducers/neo4j"
import useRequest from "./useRequest"

const boltToRestUrl = (boltUrl) =>
  `${boltUrl
    .replace(
      "bolt://",
      process.env.NODE_ENV === "development" ? "http://" : "https://"
    )
    .replace(
      ":7687",
      process.env.NODE_ENV === "development" ? ":7474" : ":7473"
    )}/db/data/transaction/commit`

const useNeo4jQuery = ({
  query,
  parameters,
  enabled,
  resultMapper = (results) => results,
}) => {
  const { url: boltUrl, username, password } = useSelector(selectNeo4j)

  const url = boltToRestUrl(boltUrl)

  const [headers, setHeaders] = useState()

  const [requestBody, setRequestBody] = useState()

  useEffect(() => {
    if (enabled) {
      setRequestBody({
        statements: [
          {
            statement: query,
            resultDataContents: ["row"],
            parameters,
          },
        ],
      })
    }
  }, [query, parameters, enabled])

  useEffect(() => {
    setHeaders({
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    })
  }, [username, password])

  return useRequest({
    url,
    method: "POST",
    headers,
    body: requestBody,
    enabled: !!(requestBody && headers),
    resultMapper: (response) => {
      const results = (response || {}).results || []
      return resultMapper(
        results.map(({ columns = [], data = [] }) => {
          const row = (data[0] || {}).row || []
          const result = {}
          columns.forEach((k, i) => {
            result[k] = row[i]
          })
          return result
        })
      )
    },
  })
}

export default useNeo4jQuery
