import React, { useState } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Form, Input, Button } from "antd"
import { LinkOutlined, UserOutlined, LockFilled } from "@ant-design/icons"

import * as Neo4JActions from "../../../store/actions/neo4j/index"

const Neo4JModal = () => {
  const [showModal, setShowModal] = useState(false)

  const neo4jAuth = useSelector((state) => state.neo4j)

  const dispatch = useDispatch()

  const handleSubmit = (data) => {
    dispatch(Neo4JActions.setNeo4jAuth(data))
    setShowModal(false)
  }

  return (
    <>
      <a href="#" onClick={() => setShowModal(true)}>
        Neo4J Authentication
      </a>
      <Modal
        visible={showModal}
        title="Neo4J Authentication"
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={neo4jAuth}
        >
          <Form.Item
            label="Neo4J Bolt Url"
            name="url"
            rules={[{ required: true, message: "Please enter neo4j url." }]}
          >
            <Input
              placeholder="Please enter neo4j url."
              prefix={<LinkOutlined />}
            />
          </Form.Item>
          <Form.Item
            label="Neo4J Username"
            name="username"
            rules={[
              { required: true, message: "Please enter neo4j username." },
            ]}
          >
            <Input
              placeholder="Please enter neo4j username."
              prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item
            label="Neo4J Password"
            name="password"
            rules={[
              { required: true, message: "Please enter neo4j password." },
            ]}
          >
            <Input.Password
              placeholder="Please enter your password."
              prefix={<LockFilled />}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

Neo4JModal.propTypes = {}

export default Neo4JModal
