import React, { useRef } from "react"
import PropTypes from "prop-types"
import { withContentRect } from "react-measure"
import { Card } from "antd"
import { useNeovis } from "../hooks"

const NeovisGraph = ({ measureRef, contentRect, cypherQuery, containerId }) => {
  const neovisRef = useRef(null)
  const viz = useNeovis(neovisRef.current, cypherQuery)
  const { width = 200, height = 200 } = contentRect.bounds || {}
  return (
    <div ref={measureRef} style={styles.container}>
      <div id={containerId} ref={neovisRef} style={styles.container} />
    </div>
  )
}

const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
}

NeovisGraph.propTypes = {
  measureRef: PropTypes.func.isRequired,
  contentRect: PropTypes.shape({
    bounds: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  cypherQuery: PropTypes.string,
  containerId: PropTypes.string,
}

NeovisGraph.defaultProps = {
  cypherQuery: undefined,
  containerId: "neovis-container",
}

export default withContentRect("bounds")(NeovisGraph)
