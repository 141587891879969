/* global sigma */
import "react-sigma/sigma/sigma.plugins.dragNodes"

let running = false
export const startDragNodes = (s) => {
  if (!running) {
    running = true
    sigma.plugins.dragNodes(s, s.renderers[0])
  }
}
export const stopDragNodes = (s) => {
  if (running) {
    running = false
    sigma.plugins.killDragNodes(s)
  }
}
