const updateProperties = (entity, oldPropertiesMap, newPropertiesMap) => {
  oldPropertiesMap.forEach((value, key) => {
    if (!newPropertiesMap.has(key)) {
      // eslint-disable-next-line no-param-reassign
      delete entity[key]
    }
  })
  newPropertiesMap.forEach((value, key) => {
    // eslint-disable-next-line no-param-reassign
    entity[key] = value
  })
}

export default updateProperties
