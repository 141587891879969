import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"
import { usePopoto } from "../hooks"

const PopotoGraph = () => {
  const { count } = usePopoto()

  return (
    <Layout className="ppt-body m-0">
      <Layout.Header className="h-32 p-0">
        <div id="popoto-cypher" className="ppt-container-query m-0" />
      </Layout.Header>
      <Layout.Content>
        <section className="ppt-section-main h-full">
          <div id="popoto-taxonomy" className="ppt-taxo-nav" />
          <div id="popoto-graph" className="ppt-div-graph" />
        </section>
      </Layout.Content>
      <Layout.Footer className="bg-transparent p-0">
        <div className="ppt-section-header m-0">
          RESULTS{" "}
          <span id="rescount" className="ppt-count">
            {count}
          </span>
        </div>
        <div id="popoto-results" className="ppt-container-results  h-40" />
      </Layout.Footer>
    </Layout>
  )
}

export default PopotoGraph
