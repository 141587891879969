import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"

const CenterView = ({ children }) => {
  return (
    <Layout.Content className="h-full items-center justify-center flex p-2 pb-12">
      {children}
    </Layout.Content>
  )
}

CenterView.propTypes = {
  children: PropTypes.node,
}

CenterView.defaultProps = {
  children: null,
}

export default CenterView
