import React from "react"

import { Card, Typography, Layout } from "antd"
import "popoto/dist/popoto.min.css"

import { CenterView } from "../../../layouts"
import PopotoGraph from "./components/PopotoGraph"

const Popoto = () => {
  return (
    <Layout className="h-full">
      <PopotoGraph />
      <Layout.Sider theme="light" />
    </Layout>
  )
}

export default Popoto
