import React, { useEffect, useRef } from "react"
import { updateProperties } from "../../helpers"

const useSigmaNodeEdgeUpdatesLight = (sigma, nodes, edges) => {
  const graphMaps = useRef({
    nodeMap: new Map(),
    edgeMap: new Map(),
  })
  const prevNodes = useRef([])
  const prevEdges = useRef([])
  const prevNodeById = useRef({})
  const prevEdgeById = useRef({})
  useEffect(() => {
    const nodeMap =
      prevNodes.current === nodes ? graphMaps.current.nodeMap : new Map()
    const edgeMap =
      prevEdges.current === edges ? graphMaps.current.edgeMap : new Map()
    if (sigma) {
      if (prevNodes.current !== nodes) {
        nodes.forEach((nextNode) => {
          if (nodeMap.has(nextNode.id)) {
            return
          }
          if (prevNodeById.current[nextNode.id] === nextNode) {
            nodeMap.set(nextNode.id, graphMaps.current.nodeMap.get(nextNode.id))
            return
          }
          prevNodeById.current[nextNode.id] = nextNode
          const { id, ...nodeFieldsRaw } = nextNode
          const nodeFieldsMap = new Map(Object.entries(nodeFieldsRaw))
          if (graphMaps.current.nodeMap.has(id)) {
            // update node
            const node = sigma.graph.nodes(id)
            const oldNodeFields = graphMaps.current.nodeMap.get(id)
            console.log("update node")
            updateProperties(node, oldNodeFields, nodeFieldsMap)
          } else {
            // add node
            console.log("added node")
            sigma.graph.addNode({
              id,
              x: Math.random(),
              y: Math.random(),
              ...nodeFieldsRaw,
            })
          }
          nodeMap.set(id, nodeFieldsMap)
        })
      }
      if (prevEdges.current !== edges) {
        edges.forEach((nextEdge) => {
          if (edgeMap.has(nextEdge.id)) {
            return
          }
          if (prevEdgeById.current[nextEdge.id] === nextEdge) {
            edgeMap.set(nextEdge.id, graphMaps.current.edgeMap.get(nextEdge.id))
            return
          }
          prevEdgeById.current[nextEdge.id] = nextEdge
          const { id, source, target, ...edgeFieldsRaw } = nextEdge
          const edgeFieldsMap = new Map(Object.entries(edgeFieldsRaw))
          if (graphMaps.current.edgeMap.has(id)) {
            // update edge
            console.log("update edge")
            const edge = sigma.graph.edges(id)
            const oldEdgeFields = graphMaps.current.edgeMap.get(id)
            updateProperties(edge, oldEdgeFields, edgeFieldsMap)
          } else {
            // add edge
            console.log("add edge")
            sigma.graph.addEdge({
              id,
              source,
              target,
              ...edgeFieldsRaw,
            })
          }
          edgeMap.set(id, edgeFieldsMap)
        })
        graphMaps.current.edgeMap.forEach((edge, id) => {
          if (!edgeMap.has(id)) {
            // delete edge
            console.log("delete edge")
            sigma.graph.dropEdge(id)
          }
        })
      }
      if (prevNodes.current !== nodes) {
        graphMaps.current.nodeMap.forEach((node, id) => {
          if (!nodeMap.has(id)) {
            // delete node
            console.log("delete node")
            sigma.graph.dropNode(id)
          }
        })
      }

      prevNodes.current = nodes
      prevEdges.current = edges
    }
    // eslint-disable-next-line no-param-reassign
    graphMaps.current = { nodeMap, edgeMap }
    if (sigma) {
      sigma.refresh()
      console.log("refreshed")
    }
  }, [sigma, nodes, edges])
}

export default useSigmaNodeEdgeUpdatesLight
