import { ARTICLE } from "../../../../constants"
import { ARTICLE_STATUSES } from "./constants"

export const getArticleStatus = ({ status, crawler }) => {
  if (crawler === ARTICLE.CRAWLER_COUNT) {
    return ARTICLE_STATUSES.NOT_FOUND
  }
  switch (status) {
    case ARTICLE.STATUSES.NEW:
    case ARTICLE.STATUSES.COPYING_TO_CRAWLER_DB:
      return ARTICLE_STATUSES.NEW
    case ARTICLE.STATUSES.COPIED_PROCESSING:
      return ARTICLE_STATUSES.PROCESSING
    case ARTICLE.STATUSES.SUCCESS:
      return ARTICLE_STATUSES.SUCCESS
    case ARTICLE.ERRORS.UNKNOWN:
    default:
      return ARTICLE_STATUSES.UNKNOWN_ERROR
  }
}
