import { COAUTHORSHIP_MODES } from "../../ArticleLists/ArticleList/constants"

/* eslint-disable max-len */

export const coauthorshipGraphQuery = {
  [COAUTHORSHIP_MODES.ARTICLE_CENTRIC]: `
UNWIND DOI_LIST as doi
MATCH (author1:Author)-[:AUTHORED]->(article:Article)<-[:AUTHORED]-(author2:Author) WHERE article.doi = doi
WITH {source:id(author1), target:id(author2)} as aRel, id(author1) as authorId
WITH collect(DISTINCT aRel) as aRelList, apoc.coll.sort(collect(DISTINCT authorId)) as aList
WITH aRelList, aList, [a in aList| algo.asNode(a)] as aNodeList
WITH [aNode in aNodeList | aNode { .lastName, .firstName, .authorId, nodeId: id(aNode) }] as result, aRelList, aList
  `,
  [COAUTHORSHIP_MODES.FIRST_AUTHOR_CENTRIC]: `
UNWIND DOI_LIST as doi
MATCH (author1:Author)-[at1:AUTHORED]->(article:Article)<-[at2:AUTHORED]-(author2:Author) WHERE article.doi=doi and (at1.order = 1 or at2.order = 1)
WITH {source:id(author1), target:id(author2)} as aRel, id(author1) as authorId
WITH collect(DISTINCT aRel) as aRelList, apoc.coll.sort(collect(DISTINCT authorId)) as aList
WITH aRelList, aList, [a in aList| algo.asNode(a)] as aNodeList
WITH [aNode in aNodeList | aNode { .lastName, .firstName, .authorId, nodeId: id(aNode) }] as result, aRelList, aList
`,
  [COAUTHORSHIP_MODES.LAST_AUTHOR_CENTRIC]: `
UNWIND DOI_LIST as doi
MATCH (article:Article) WHERE article.doi = doi
WITH article, size((article)<-[:AUTHORED]-(:Author)) as maxOrder
MATCH (author1:Author)-[at1:AUTHORED]->(article)<-[at2:AUTHORED]-(author2:Author) WHERE (at1.order = maxOrder or at2.order = maxOrder)
WITH {source:id(author1), target:id(author2)} as aRel, id(author1) as authorId
WITH collect(DISTINCT aRel) as aRelList, apoc.coll.sort(collect(DISTINCT authorId)) as aList
WITH aRelList, aList, [a in aList| algo.asNode(a)] as aNodeList
WITH [aNode in aNodeList | aNode { .lastName, .firstName, .authorId, nodeId: id(aNode) }] as result, aRelList, aList
`,
}

export default (
  doiList,
  { coauthorshipMode = COAUTHORSHIP_MODES.ARTICLE_CENTRIC } = {}
) => `WITH ${JSON.stringify(doiList)}
AS DOI_LIST

${coauthorshipGraphQuery[coauthorshipMode]}

CALL algo.closeness.stream(
'UNWIND $aList as id RETURN id',
'UNWIND $aRelList as aRel RETURN aRel.source as source, aRel.target as target',
{ graph: 'cypher', write: false, params: { aRelList: aRelList, aList: aList } }
) YIELD nodeId, centrality
WITH nodeId, centrality, aRelList, aList, result ORDER BY nodeId
WITH collect({ nodeId: nodeId, centrality: centrality }) as centralityList, aRelList, aList, result
WITH [i IN range(0, size(aList) - 1) | apoc.map.setKey(result[i], 'closeness', centralityList[i].centrality)] as result, aList, aRelList

CALL algo.degree.stream(
'UNWIND $aList as id RETURN id',
'UNWIND $aRelList as aRel RETURN aRel.source as source, aRel.target as target',
{ graph: 'cypher', write: false, params: { aRelList: aRelList, aList: aList } }
) YIELD nodeId, score as centrality
WITH nodeId, centrality, aRelList, aList, result ORDER BY nodeId
WITH collect({ nodeId: nodeId, centrality: centrality }) as centralityList, aRelList, aList, result
WITH [i IN range(0, size(aList) - 1) | apoc.map.setKey(result[i], 'degree', centralityList[i].centrality)] as result, aList, aRelList

CALL algo.betweenness.stream(
'UNWIND $aList as id RETURN id',
'UNWIND $aRelList as aRel RETURN aRel.source as source, aRel.target as target',
{ graph: 'cypher', write: false, params: { aRelList: aRelList, aList: aList } }
) YIELD nodeId, centrality
WITH nodeId, centrality, aRelList, aList, result ORDER BY nodeId
WITH collect({ nodeId: nodeId, centrality: centrality }) as centralityList, aRelList, aList, result
WITH [i IN range(0, size(aList) - 1) | apoc.map.setKey(result[i], 'betweenness', centralityList[i].centrality)] as result, aList, aRelList

CALL algo.eigenvector.stream(
'UNWIND $aList as id RETURN id',
'UNWIND $aRelList as aRel RETURN aRel.source as source, aRel.target as target',
{ graph: 'cypher', write: false, normalization: 'max', params: { aRelList: aRelList, aList: aList } }
) YIELD nodeId, score as centrality
WITH nodeId, centrality, aRelList, aList, result ORDER BY nodeId
WITH collect({ nodeId: nodeId, centrality: centrality }) as centralityList, aRelList, aList, result
WITH [i IN range(0, size(aList) - 1) | apoc.map.setKey(result[i], 'eigenvector', centralityList[i].centrality)] as result, aList, aRelList

CALL algo.unionFind.stream('UNWIND $aList as id RETURN id',
'UNWIND $aRelList as aRel RETURN aRel.source as source, aRel.target as target',
{ graph: 'cypher', write: false, normalization: 'max', params: { aRelList: aRelList, aList: aList } }
) YIELD nodeId, setId as clusterId
WITH nodeId, clusterId, aRelList, aList, result ORDER BY nodeId
WITH collect({ nodeId: nodeId, clusterId: clusterId }) as clusterList, aRelList, aList, result
WITH [i IN range(0, size(aList) - 1) | apoc.map.setKey(result[i], 'cluster', clusterList[i].clusterId)] as result, aList, aRelList

RETURN result as authors, aRelList as coauthorRelationships
`
