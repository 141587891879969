import React, { useMemo, useState } from "react"

import {
  Card,
  Typography,
  Layout,
  Form,
  Input,
  Button,
  Select,
  Collapse,
} from "antd"

import { CenterView } from "../../../layouts"
import SigmaGraph from "./components/SigmaGraph"

import { doilistCypherQuery, initialDoiList } from "./constants"
import { useNeo4jQuery } from "../../../hooks"

const Sigma = () => {
  const [form] = Form.useForm()
  const [doiList, setDoiList] = useState(initialDoiList)

  /* const coauthorshipBody = useMemo(() => {
    const body = {
      doiList,
      coauthorshipMode: COAUTHORSHIP_MODES.ARTICLE_CENTRIC,
    }
    return body
  }, [doiList])
  const [coauthorshipQueryState, coauthorshipQueryRetry] = useRequest({
    url: `graph/graph/coauthorship`,
    method: "POST",
    body: coauthorshipBody,
    enabled: doiList.length > 0,
  }) */

  const coauthorshipQuery = useMemo(() => doilistCypherQuery(doiList), [
    doiList,
  ])
  const [coauthorshipQueryState, coauthorshipQueryRetry] = useNeo4jQuery({
    enabled: doiList.length > 0,
    query: coauthorshipQuery,
    resultMapper: (results) => results[0],
  })

  return (
    <Layout className="h-full">
      <Layout>
        <Layout.Header className="h-auto py-2 bg-red-200 px-4">
          <Collapse>
            <Collapse.Panel key="doi-list" header="View / Edit Doi List">
              <Form
                form={form}
                layout="inline"
                onFinish={(d) => setDoiList(d.doiList)}
                className="flex items-stretch content-center justify-center"
                initialValues={{
                  doiList: initialDoiList,
                }}
              >
                <Form.Item name="doiList" noStyle>
                  <Select mode="tags" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit" className="h-auto">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Layout.Header>
        <Layout.Content>
          <SigmaGraph
            coauthorshipQueryState={coauthorshipQueryState}
            coauthorshipQueryRetry={coauthorshipQueryRetry}
          />
          {/* <NeovisGraph cypherQuery={cypherQuery} /> */}
        </Layout.Content>
      </Layout>
      <Layout.Sider theme="light">{/* <NeovisOptions /> */}</Layout.Sider>
    </Layout>
  )
}

export default Sigma
