import React, { useState } from "react"

import { Card, Typography, Layout, Button } from "antd"
import { Switch, Route, Redirect, useParams } from "react-router-dom"

import moment from "moment"
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { CenterView, ScrollView } from "../../../../layouts"
import { useRequest } from "../../../../hooks"
import Loading from "../../../../components/Loading"
import ArticleListNavbar from "./Navbar"
import ArticleTable from "./ArticleTable"
import AuthorTable from "./AuthorTable"
import Graph from "./Graph"
import Sidebar from "./Sidebar"
import ArticleListModal from "./Modal"
import { withArticleListProvider, useArticleListContext } from "./Context"
import { useAuth0 } from "../../../../modules/auth0"

const ArticleList = () => {
  const { user } = useAuth0()
  const { articleListId } = useParams()
  /* const [{ loading, loaded, error, result: articleList }, retry] = useRequest({
    url: `article/articleList/${articleListId}`,
    method: "GET",
  }) */
  const { requests = {}, articleDois, authorIds } = useArticleListContext()
  const {
    state: { loading, loaded, error, result: articleList },
    retry,
  } = requests.articleList || {}
  const [updatingArticle, setUpdatingArticle] = useState("")
  return (
    <Layout className="h-full">
      <Layout.Header className="bg-indigo-200 flex items-center overflow-x-auto">
        <Loading
          loading={loading}
          loaded={loaded}
          error={error}
          retry={retry}
          className="mx-8"
        >
          <div className="mr-4 flex items-center">
            <Typography.Title
              className="mt-0 mb-1 mr-4 whitespace-no-wrap"
              level={2}
            >
              {(articleList || {}).title}
            </Typography.Title>
            {user.sub === (articleList || {}).authorId ? (
              <>
                <Button
                  type="primary"
                  shape="circle"
                  className="blue-btn mx-1"
                  onClick={() => setUpdatingArticle(articleListId)}
                  icon={<EditOutlined />}
                />
                <Button
                  type="danger"
                  shape="circle"
                  className="mx-1"
                  icon={<DeleteOutlined />}
                />
              </>
            ) : null}
            <Button
              type="primary"
              shape="circle"
              className="mx-1"
              icon={<CopyOutlined />}
            />
          </div>
        </Loading>
        <ArticleListNavbar />
      </Layout.Header>
      <Layout>
        <Layout.Content className="bg-indigo-100">
          <Switch>
            <Route
              exact
              path="/articleLists/:articleListId/articles"
              render={() => (
                <div className="m-4">
                  <ArticleTable articleDois={articleDois} />
                </div>
              )}
            />
            <Route
              exact
              path="/articleLists/:articleListId/authors"
              render={() => (
                <div className="m-4">
                  <AuthorTable authorIds={authorIds} />
                </div>
              )}
            />
            <Route
              exact
              path="/articleLists/:articleListId/graph"
              component={Graph}
            />
            <Route
              exact
              path="/articleLists/:articleListId/graph/3d"
              render={() => <Graph is3d />}
            />
            <Redirect to={`/articleLists/${articleListId}/graph`} />
          </Switch>
          <ArticleListModal
            articleListId={updatingArticle}
            visible={!!updatingArticle}
            onClose={() => setUpdatingArticle("")}
            onSuccess={retry}
          />
        </Layout.Content>
        <Sidebar />
      </Layout>
    </Layout>
  )
}

export default withArticleListProvider(ArticleList)
