import React from "react"

import GuestRoutes from "./Guest"
import UserRoutes from "./User"
import { useAuth0 } from "../modules/auth0"

const Routes = () => {
  const { loading, user, isAuthenticated } = useAuth0()

  console.log("user", user)

  if (loading) {
    return <div>loading...</div>
  }

  if (isAuthenticated) {
    return <UserRoutes />
  }
  return <GuestRoutes />
}

export default Routes
