import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Sigma,
  RelativeSize,
  RandomizeNodePositions,
  NeoCypher,
} from "react-sigma"
import ForceLink from "react-sigma/lib/ForceLink"
import { useSelector } from "react-redux"
import { edgePropType, nodePropType } from "../types"
import SigmaDynamicNodesEdges from "./private/SigmaDynamicNodesEdges"
import useSigma from "../hooks/useSigma"
import { selectNeo4j } from "../../../../store/reducers/neo4j"
import { CenterView } from "../../../../layouts"
import { doilistCypherQuery } from "../constants"
import { useNeo4jQuery, useRequest } from "../../../../hooks"
import Loading from "../../../../components/Loading"
import SigmaPlugins from "./private/SigmaPlugins"

const settings = {
  drawEdges: true,
  clone: true,
  immutable: true,
  minEdgeSize: 0.001,
  maxEdgeSize: 2,
  minNodeSize: 1,
  maxNodeSize: 32,
}
const SigmaGraph = ({
  coauthorshipQueryState,
  coauthorshipQueryRetry,
  getNodeSize,
  getNodeLabel,
  getNodeColor,
}) => {
  const [graph, setGraph] = useState({ nodes: [], edges: [] })

  const { nodes, edges } = graph

  const { loading, loaded, error, result } = coauthorshipQueryState

  useEffect(() => {
    if (!loading && !error && loaded) {
      /* console.log("result", result) */
      const { authors, coauthorRelationships } = result
      console.log("reset graph !")
      setGraph({
        nodes: authors.map((author) => ({
          id: author.nodeId,
          label: getNodeLabel(author),
          size: getNodeSize(author),
          color: getNodeColor(author),
          x: Math.random(),
          y: Math.random(),
        })),
        edges: coauthorRelationships.map(({ source, target }) => ({
          id: `${source}-${target}`,
          color: "rgba(130, 247, 12, 0.25)",
          size: 0.001,
          source,
          target,
        })),
      })
    }
  }, [loading, loaded, error, result])
  useEffect(() => {
    if (!loading && !error && loaded && nodes.length > 0) {
      const { authors } = result
      setGraph({
        ...graph,
        nodes: nodes.map((node, index) => ({
          ...node,
          label: getNodeLabel(authors[index]),
          size: getNodeSize(authors[index]),
          color: getNodeColor(authors[index]),
        })),
      })
    }
  }, [getNodeSize, getNodeColor, getNodeLabel])
  /* const [containerRef, sigma] = useSigma({ nodes: poop, edges }) */

  /* if (!url || !username || !password) {
    return <CenterView>Please set neo4j authentication parameters</CenterView>
  } */

  return (
    <>
      {/*      <button type="button" onClick={pushRandomNode} style={styles.btn}>
        Poop
      </button> */}
      {/*      <div
        ref={containerRef}
        style={{ width: 200, height: 200, background: "#ddd" }}
      >
        Hello
      </div> */}
      <Sigma renderer="canvas" settings={settings} style={styles.sigma}>
        <SigmaDynamicNodesEdges edges={edges} nodes={nodes}>
          <SigmaPlugins />
        </SigmaDynamicNodesEdges>
        {/* <RelativeSize initialSize={15} /> */}
        {/* <RandomizeNodePositions /> */}
      </Sigma>
    </>
  )
}

SigmaGraph.propTypes = {
  coauthorshipQueryState: useRequest.createPropType(
    PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          eigenvector: PropTypes.number.isRequired,
          betweenness: PropTypes.number.isRequired,
          closeness: PropTypes.number.isRequired,
          degree: PropTypes.number.isRequired,
        })
      ).isRequired,
      coauthorRelationships: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          target: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        })
      ).isRequired,
    }).isRequired
  ).isRequired,
  coauthorshipQueryRetry: PropTypes.func.isRequired,
  getNodeSize: PropTypes.func,
  getNodeLabel: PropTypes.func,
  getNodeColor: PropTypes.func,
}

SigmaGraph.defaultProps = {
  getNodeSize: (node) => node.closeness * node.eigenvector,
  getNodeLabel: ({ firstName, lastName }) =>
    `${(firstName || "").substr(0, 1)}.${lastName || ""}`,
  getNodeColor: (node) => "red",
}

const styles = {
  sigma: {
    width: "100%",
    height: "100%",
  },
  btn: {
    position: "absolute",
    zIndex: 2,
  },
}

export default SigmaGraph
