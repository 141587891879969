import PropTypes from "prop-types"

const nodePropType = PropTypes.shape({
  /* main attributes */
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  /* display attributes */
  /* x: PropTypes.number,
  y: PropTypes.number, */
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
})

const edgePropType = PropTypes.shape({
  /* main attributes */
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  size: PropTypes.number,
  /* display attributes */
  label: PropTypes.string,
  color: PropTypes.string,
})

export { nodePropType, edgePropType }
