import React, { useEffect, useReducer } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { API_URL } from "../constants"
import { useAuth0 } from "../modules/auth0"

const FETCH_REQUEST = "FETCH_REQUEST"
const FETCH_SUCCESS = "FETCH_SUCCESS"
const FETCH_ERROR = "FETCH_ERROR"

const initialState = {
  loading: false,
  loaded: false,
  error: "",
  result: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: state.loaded,
        error: "",
      }
    case FETCH_SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: "",
        result: action.data,
      }
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      }
    default:
      return state
  }
}

function useRequest({
  resultMapper = (data) => data,
  url,
  method,
  body,
  headers,
  extras,
  enabled = true,
}) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { getTokenSilently } = useAuth0()
  const reloadRequest = async () => {
    if (!url || !method || !enabled) {
      return
    }
    dispatch({ type: FETCH_REQUEST })
    let request = null

    try {
      const token = await getTokenSilently()
      const finalHeaders = {
        Authorization: `Bearer ${token}`,
        ...headers,
      }
      switch (method.toUpperCase()) {
        case "GET":
        case "HEAD":
          request = axios({
            url,
            method,
            headers: finalHeaders,
            params: body,
            baseURL: API_URL,
            ...extras,
          })
          break
        case "POST":
        case "PUT":
        case "DELETE":
          request = axios({
            url,
            method,
            headers: finalHeaders,
            data: body,
            baseURL: API_URL,
            ...extras,
          })
          break
        default:
          throw new Error("Unknown method in request hook, ", method)
      }

      const response = await request
      dispatch({
        type: FETCH_SUCCESS,
        data: resultMapper(response.data),
      })
    } catch (e) {
      dispatch({ type: FETCH_ERROR, error: e.message })
    }
  }
  useEffect(() => {
    if (url && method && enabled) {
      reloadRequest().catch((e) => console.error(e))
    }
  }, [url, enabled, method, body, headers, extras])
  return [state, reloadRequest]
}

useRequest.methods = {
  GET: "GET",
  HEAD: "HEAD",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
}

useRequest.createPropType = (resultPropType = PropTypes.any) =>
  PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    result: resultPropType,
  })

export default useRequest
