import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Menu, Modal, Form, Button, Select } from "antd"
import {
  selectNeovisLabels,
  selectNeovisRelationships,
} from "../../../../store/reducers/neovis"
import * as NeovisActions from "../../../../store/actions/neovis"
import { Neo4JHelper } from "../../../../helpers"

const entityKeySet = new Set(Neo4JHelper.Entities.keys)
const relationKeySet = new Set(Neo4JHelper.Relations.keys)

const entityOptions = [
  { key: "caption", from: "strings" },
  { key: "size", from: "numbers" },
  { key: "cluster", from: "numbers" },
]

const relationOptions = [
  { key: "caption", from: "strings" },
  { key: "thickness", from: "numbers" },
]

const fixedSizes = [1.0, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001]

const NeovisOptions = () => {
  const labels = useSelector(selectNeovisLabels)
  const relations = useSelector(selectNeovisRelationships)

  const [activeKey, setActiveKey] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeKey === "reset") {
      setActiveKey(null)
      dispatch(NeovisActions.resetNeovisOptions())
    } else {
      setShowModal(!!activeKey)
    }
  }, [activeKey])

  useEffect(() => {
    if (!showModal) {
      setTimeout(() => setActiveKey(null), 100)
    }
  }, [showModal])

  // eslint-disable-next-line no-nested-ternary
  const initialValues = entityKeySet.has(activeKey)
    ? labels[activeKey]
    : relationKeySet.has(activeKey)
    ? relations[activeKey]
    : undefined

  // eslint-disable-next-line no-nested-ternary
  const fields = entityKeySet.has(activeKey)
    ? entityOptions
    : relationKeySet.has(activeKey)
    ? relationOptions
    : []

  // eslint-disable-next-line no-nested-ternary
  const model = entityKeySet.has(activeKey)
    ? Neo4JHelper.Entities[activeKey]
    : relationKeySet.has(activeKey)
    ? Neo4JHelper.Relations[activeKey]
    : {}

  const handleUpdate = (rawData) => {
    let data = { ...rawData }
    fields.forEach(({ key }) => {
      if (!data[key]) {
        data[key] = false
      }
    })
    data = { key: activeKey, value: data }
    if (entityKeySet.has(activeKey)) {
      dispatch(NeovisActions.updateNeovisEntity(data))
    } else {
      dispatch(NeovisActions.updateNeovisRelation(data))
    }
    setActiveKey(null)
  }

  console.log("model", model)

  return (
    <Menu
      mode="inline"
      onClick={({ key }) => setActiveKey(key)}
      selectedKeys={activeKey ? [activeKey] : []}
    >
      <Menu.SubMenu
        key="entities"
        title={
          <span>
            <span>Entities</span>
          </span>
        }
      >
        {Neo4JHelper.Entities.keys.map((key) => (
          <Menu.Item key={key}>
            <span>{key}</span>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="relations"
        title={
          <span>
            <span>Relations</span>
          </span>
        }
      >
        {Neo4JHelper.Relations.keys.map((key) => (
          <Menu.Item key={key}>
            <span>{key}</span>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item key="reset">
        <span>Reset</span>
      </Menu.Item>
      <Modal
        visible={showModal}
        title={activeKey}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        {activeKey ? (
          <Form
            onFinish={handleUpdate}
            initialValues={initialValues}
            layout="vertical"
          >
            {fields.map(({ key, from }) => (
              <Form.Item key={key} name={key} label={key}>
                <Select allowClear>
                  {(model[from] || []).map((value) => (
                    <Select.Option value={value} key={value}>
                      {value}
                    </Select.Option>
                  ))}
                  {key === "size" || key === "thickness" ? (
                    fixedSizes.map((size) => (
                      <Select.Option value={size} key={size}>
                        {size}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="false">Hidden</Select.Option>
                  )}
                </Select>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </Menu>
  )
}

export default NeovisOptions
