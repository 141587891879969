import { SET_NEO4J_AUTH } from "../../types"

const initialState = {
  url: "bolt://",
  username: "",
  password: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEO4J_AUTH:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default reducer

export const selectNeo4j = (state) => state.neo4j
