import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { BrowserRouter } from "react-router-dom"

import Routes from "./routes"
import store, { persistor } from "./store"
import { Auth0Provider } from "./modules/auth0"

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Auth0Provider>
          <Routes />
        </Auth0Provider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

export default App
