import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

import { Skeleton, Card, Typography, List, Tag, Button } from "antd"
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderAddFilled,
  FolderAddOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons"
import { Link, useParams } from "react-router-dom"
import moment from "moment"

import { ScrollView } from "../../../layouts"
import { useRequest } from "../../../hooks"
import Loading from "../../../components/Loading"
import ArticleListModal from "./ArticleList/Modal"
import { useAuth0 } from "../../../modules/auth0"

const ArticleLists = ({ latest }) => {
  const { user } = useAuth0()
  const { userId = "" } = useParams()
  const [{ loading, loaded, error, result }, retry] = useRequest({
    /* eslint-disable-next-line no-nested-ternary */
    url: userId
      ? `article/articleList/user/${userId}`
      : latest
      ? `article/articleList/latest`
      : `article/articleList`,
    method: "GET",
    resultMapper: (articleLists) =>
      [...(articleLists || [])]
        .map((a) => ({
          ...a,
          createdAt: moment(a.createdAt),
          updatedAt: moment(a.updatedAt),
        }))
        .sort((a, b) => b.createdAt.diff(a.createdAt)),
  })

  const pageUserName = useMemo(() => {
    if (userId) {
      return (
        (result || []).reduce((acc, res) => acc || res.authorName, "") || ""
      )
    }
    return ""
  }, [userId, result])

  const [updatingArticle, setUpdatingArticle] = useState("")
  return (
    <ScrollView className="justify-center">
      <Card className="max-w-4xl mx-auto">
        <Loading
          loading={false}
          loaded
          error={error}
          retry={retry}
          className="min-h-50-vh"
          size="xlarge"
        >
          <List
            loading={loading}
            header={
              <div>
                {
                  /* eslint-disable-next-line no-nested-ternary */
                  userId
                    ? `Article Lists of ${pageUserName}`
                    : latest
                    ? `Latest Article Lists`
                    : "My Article Lists"
                }
              </div>
            }
            footer={
              <Button
                className="w-full"
                type="primary"
                size="large"
                icon={<FolderAddFilled />}
                onClick={() => setUpdatingArticle("new")}
              >
                Create New Article List
              </Button>
            }
            bordered
            dataSource={result || [{}, {}, {}, {}]}
            renderItem={({
              title,
              _id,
              authorId,
              authorName,
              public: isPublic,
              createdAt,
              updatedAt,
              articles,
            }) => (
              <List.Item
                actions={
                  authorId === user.sub
                    ? [
                        <Button
                          type="primary"
                          shape="circle"
                          className="blue-btn"
                          onClick={() => setUpdatingArticle(_id)}
                          icon={<EditOutlined />}
                        />,
                        <Button
                          type="danger"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />,
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<CopyOutlined />}
                        />,
                      ]
                    : [
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<CopyOutlined />}
                        />,
                      ]
                }
              >
                <Skeleton title={false} loading={!_id} active>
                  <List.Item.Meta
                    title={
                      <div>
                        <Link to={`/articleLists/user/${authorId}`}>
                          {authorName}
                        </Link>
                        {" / "}
                        <Link to={`/articleLists/${_id}`}>{title}</Link>
                        <Tag
                          color={isPublic ? "cyan" : "magenta"}
                          className="mx-2"
                        >
                          {isPublic ? "Public" : "Private"}
                        </Tag>
                      </div>
                    }
                    description={
                      <div>
                        <span>
                          Contains {(articles || []).length} articles.{" "}
                        </span>
                        <span>
                          Created {createdAt && createdAt.fromNow()}.{" "}
                        </span>
                        <span>
                          Updated {updatedAt && updatedAt.fromNow()}.{" "}
                        </span>
                      </div>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Loading>
        <ArticleListModal
          onClose={() => setUpdatingArticle("")}
          articleListId={updatingArticle === "new" ? "" : updatingArticle}
          visible={!!updatingArticle}
          onSuccess={retry}
        />
      </Card>
    </ScrollView>
  )
}

ArticleLists.propTypes = {
  latest: PropTypes.bool,
}

ArticleLists.defaultProps = {
  latest: false,
}

const styles = {
  body: {
    minHeight: "60vh",
  },
}

export default ArticleLists
