import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useArticleListContext } from "./Context"
import SigmaGraph from "../../Sigma/components/SigmaGraph"
import ForceGraph from "../../ForceGraph/components/ForceGraph"
import ForceGraph3 from "../../ForceGraph/components/ForceGraph3"

const MIN_NODE_SIZE = 1
const NODE_SIZE_CONSTANT = 32

const Graph = ({ is3d }) => {
  const {
    requests,
    helpers,
    options,
    authorsById = {},
  } = useArticleListContext()
  const {
    state: coauthorShipQueryState = {},
    retry: coauthorshipQueryRetry = () => {},
  } = requests.coauthorshipQuery || {}
  const { getClusterColor, getInfluence } = helpers
  /* const { influencePreset } = options
  const { weights } = (influencePreset || {}).state || {}
  const getNodeSize = useMemo(() => {
    return (node) =>
      Object.entries(weights).reduce(
        (total, [key, weight]) => total + (node[key] || 0) * weight,
        0
      )
  }, [weights]) */
  const getNodeSize = useMemo(
    () => (node) => MIN_NODE_SIZE + getInfluence(node) * NODE_SIZE_CONSTANT,
    [getInfluence]
  )
  const getNodeColor = useMemo(() => (node) => getClusterColor(node.cluster), [
    getClusterColor,
  ])
  const getNodeLabel = useMemo(
    () => (node) => `${node.firstName} ${node.lastName || ""}`,
    []
  )
  return is3d ? (
    <ForceGraph3
      coauthorshipQueryState={coauthorShipQueryState}
      coauthorshipQueryRetry={coauthorshipQueryRetry}
      getNodeSize={getNodeSize}
      getNodeColor={getNodeColor}
      getNodeLabel={getNodeLabel}
    />
  ) : (
    <ForceGraph
      coauthorshipQueryState={coauthorShipQueryState}
      coauthorshipQueryRetry={coauthorshipQueryRetry}
      getNodeSize={getNodeSize}
      getNodeColor={getNodeColor}
      getNodeLabel={getNodeLabel}
    />
  )
}

Graph.propTypes = {
  is3d: PropTypes.bool,
}

Graph.defaultProps = {
  is3d: false,
}

export default Graph
